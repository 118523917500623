<!--
 * @Description: 
 * @Author: hongyan.yang
 * @LastEditors: zhongyuanrui
 * @FilePath: \homeauto-web-v3.0\src\components\baseCard\BaseCard.vue
-->
<template>
  <el-card class="base-card">
    <div slot="header" class="base-card-header flex-between">
      <span>{{ title }}</span>
      <slot name="right"></slot>
    </div>
    <slot></slot>
  </el-card>
</template>

<script>
export default {
  name: "BaseCard",
  props: {
    title: {
      type: String
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .el-card__header {
  padding: 0;
  height: 60px;
}
.base-card-header {
  font-weight: 500;
  color: #333333;
  padding-left: 20px;
  font-size: 14px;
  line-height: 60px;
  height: 60px;
}
</style>
