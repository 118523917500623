<template>
  <el-header class="base-header">
    <div class="base-header-left">
      <hamburger :is-active="sidebarOpen" class="hamburger-container" @toggleClick="toggleSideBar" />
    </div>
    <div class="base-header-right">
      <div class="system-name">智慧云平台-部署系统</div>
      <user-menu></user-menu>
    </div>
  </el-header>
</template>

<script>
import UserMenu from "./UserMenu.vue";
import Hamburger from "../hamburger/index";
import { mapGetters } from "vuex";
export default {
  name: "BaseHeader",
  components: { Hamburger, UserMenu },
  computed: {
    ...mapGetters(["sidebarOpen"])
  },
  methods: {
    toggleSideBar() {
      this.$store.commit("TOGGLE_SIDEBAR");
    }
  }
};
</script>

<style lang="scss" scoped>
.base-header {
  position: relative;
  display: flex;
  justify-content: space-between;
  background: #fff;
  box-shadow: 0px 1px 4px 0px rgba(0, 21, 41, 0.12);
  &-left {
    display: flex;
    align-items: center;
  }
  &-right {
    display: flex;
    align-items: center;
    .system-name {
      padding-left: 24px;
      padding-right: 40px;
      font-weight: 500;
      font-size: 16px;
      color: #555555;
    }
  }
}
</style>
