<!--
 * @Description: 表格按钮
 * @Author: hongyan.yang
 * @LastEditors: hongyan.yang
 * @FilePath: /household/src/components/baseButton/TableButton.vue
-->
<template>
  <el-button :class="[type, 'btn']" type="text" plain size="small" @click="$emit('click')">
    <slot></slot>
  </el-button>
</template>

<script>
export default {
  name: "TableButton",
  components: {},
  props: {
    type: {
      type: String,
      default: "info"
    }
  },
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {}
};
</script>

<style lang="scss" scoped>
.btn {
  padding: 0 18px;
  line-height: 18px;
  height: 32px;
  outline: none !important;
}
.el-button--text {
  border: none !important;
}
.el-button.primary:hover,
.el-button.primary:focus {
  background: #e5f9f7 !important;
}
.el-button.danger:hover,
.el-button.danger:focus {
  background: #ffefef !important;
}
.el-button.info:hover,
.el-button.info:focus {
  background: #ecf6ff !important;
}
.primary {
  color: #03ada2 !important;
}
.danger {
  color: #f56c6c !important;
}
.info {
  color: #1890ff !important;
}
.el-button + .el-button {
  margin: 0;
}
</style>
