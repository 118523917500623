/*
 * @Description:
 * @Author: hongyan.yang
 * @LastEditors: hongyan.yang
 * @FilePath: /household/src/store/index.js
 */
import Vue from "vue";
import Vuex from "vuex";
import getters from "./getters";
import user from "./modules/user";
import permission from "./modules/async-router";

import app from "./modules/app";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  getters,
  modules: {
    user,
    permission,
    app
  }
});
