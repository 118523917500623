/*
 * @Description:
 * @Author: zhongyuanrui
 * @LastEditors: zhongyuanrui
 * @FilePath: \homeauto-web-v3.0\src\api\common.js
 */
import request from "@/plugins/axios";

// 查询启用的字典标签
export function searchEnableTagDic(dicCode) {
  return request({
    url: "/homeauto-center-device/device/web/dic/tag/list/web",
    method: "get",
    params: { dicCode }
  });
}
// 获取所有行政区数据
export function getAreaList() {
  return request({
    url: "/homeauto-center-device/device/web/area/cascadeList",
    method: "get"
  });
}
// 上传文件
export function apiFileUpload() {
  return request({
    url: "/homeauto-center-device/device/web/area/cascadeList",
    method: "post"
  });
}
