<template>
  <el-container class="basic-layout">
    <sidebar></sidebar>
    <el-container class="basic-layout-content" direction="vertical">
      <base-header />
      <breadcrumb v-if="!$route.meta.hiddenBreadcrumb"></breadcrumb>
      <el-main class="app-main">
        <keep-alive :include="keepAliveList">
          <router-view />
        </keep-alive>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import Breadcrumb from "../breadcrumb/Breadcrumb.vue";
import Sidebar from "../sidebar/Sidebar";
import BaseHeader from "../baseHeader/BaseHeader";
import { mapGetters } from "vuex";
export default {
  name: "BasicLayout",
  components: { BaseHeader, Sidebar, Breadcrumb },
  computed: {
    ...mapGetters(["sidebarOpen", "keepAliveList"])
  }
};
</script>

<style lang="scss" scoped>
.basic-layout {
  height: 100vh;
  background: #f6f6f6;
  &-content {
    position: relative;
    ::v-deep .el-header {
      padding: 0;
    }
    .app-main {
      padding: 0 20px 14px;
    }
  }
}
</style>
