<!--
 * @Description: 通用卡片类表格
 * @Author: hongyan.yang
 * @LastEditors: hongyan.yang
 * @FilePath: /household/src/components/cardTable/CardTable.vue
-->
<template>
  <el-card class="sub-card">
    <div slot="header" class="flex-between">
      <span>
        <span>{{ title }}</span>
        <el-tooltip v-if="tip" class="item" effect="dark" :content="tip" placement="right">
          <i class="el-icon-warning-outline"></i>
        </el-tooltip>
      </span>
      <el-button v-if="opBtn" size="small" type="primary" @click="$emit('edit')">{{ opBtn }}</el-button>
    </div>
    <el-table
      v-if="showTable"
      border
      :data="tableData"
      :header-cell-style="{ background: '#fff' }"
      :row-class-name="tableRowClassName"
    >
      <el-table-column :label="item.label" v-for="item in columns" :key="item.name" :prop="item.name">
      </el-table-column>
      <slot name="table-btn"></slot>
    </el-table>
    <slot />
  </el-card>
</template>

<script>
export default {
  name: "CardTable",
  components: {},
  props: {
    title: {},
    opBtn: {},
    tip: {},
    columns: {},
    tableData: {},
    showTable: {
      default: true
    }
  },
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {
    tableRowClassName({ rowIndex }) {
      if (rowIndex % 2 == 0) {
        return "warning-row";
      }
      return "";
    }
  }
};
</script>

<style lang="scss" scoped>
.sub-card {
  margin-top: 20px;
  box-shadow: none;
  ::v-deep .el-card__header {
    padding: 14px 20px !important;
    line-height: 32px;
    font-size: 14px;
    font-weight: 500;
    color: #333333;
  }
}
.el-icon-warning-outline {
  padding-left: 12px;
  color: #a3b7c8;
}
::v-deep .el-card__body {
  padding: 0;
}
::v-deep .el-table--border {
  border-top: none;
}
::v-deep .warning-row {
  background: #fafafa;
}
</style>
