/*
 * @Author: hongyan.yang
 * @Date: 2020-03-19 14:50:44
 * @LastEditors: hongyan.yang
 * @LastEditTime: 2020-06-23 17:02:06
 * @FilePath: /household/src/utils/domUtil.js
 */

const domTitle = "智慧部署平台";

/**
 * @author: hongyan.yang
 * @question: 用document.title设置title，在返回时不会再重新设置title。由于iOS的微信浏览器使用原生的title,在路由返回后不能及时捕获document.title的修改。
 * @解决方法: 在document里append一个空的iframe做伪请求，在修改完标题后进行remove
 */

export const setDocumentTitle = function (meta) {
  document.title = meta && typeof meta.title !== "undefined" ? meta.title - domTitle : domTitle;
  const ua = navigator.userAgent;
  // eslint-disable-next-line
  const regex = /\bMicroMessenger\/([\d\.]+)/;
  if (regex.test(ua) && /ip(hone|od|ad)/i.test(ua)) {
    const i = document.createElement("iframe");
    i.src = "/favicon.ico";
    i.style.display = "none";
    i.onload = function () {
      setTimeout(function () {
        i.remove();
      }, 9);
    };
    document.body.appendChild(i);
  }
};
