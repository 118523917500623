<!--
 * @Description: 
 * @Author: hongyan.yang
 * @LastEditors: hongyan.yang
 * @FilePath: /household/src/components/basePagination/BasePagination.vue
-->
<template>
  <el-pagination
    style="text-align:right"
    v-if="model"
    @size-change="handleSizeChange"
    @current-change="handleCurrentChange"
    v-bind="model"
  >
  </el-pagination>
</template>

<script>
export default {
  name: "BasePagination",
  props: {
    model: {}
  },
  methods: {
    handleSizeChange(val) {
      this.$emit("change", { pageSize: val });
    },
    handleCurrentChange(val) {
      this.$emit("change", { currentPage: val });
    }
  }
};
</script>
