/*
 * @Description:
 * @Author: hongyan.yang
 * @LastEditors: hongyan.yang
 * @FilePath: /household/src/components/baseInput/baseInputProperty.js
 */

export const VariableType = {
  TEXT: 1,
  NUMBER: 2,
  TEXTAREA: 3,
  PASSWORD: 4,
  INPUT_NUMBER: 5,
  SELECT: 6,
  DATE: 7,
  DATETIME: 8,
  DATERANGE: 9,
  DATETIMERANGE: 10,
  SWITCH: 11,
  CASCADER: 12,
  SELECTED_INPUT: 13
};

export function DefaultValue(type) {
  switch (type) {
    case VariableType.CASCADER:
      return [];
    default:
      return "";
  }
}

export class baseInputProperty {
  variable = "";
  label = "";
  value = undefined;
  placeholder = "";
  width;
  type = VariableType.TEXT;
  options = null;
  clearable = false;

  static Init(label, variable, type, value, placeholder) {
    let r = new this();
    r.variable = variable;
    r.label = label;
    // 布尔值特殊处理，因为后端传递的是0和1
    if (type === VariableType.SWITCH) {
      r.value = value == 0 ? false : true;
    } else {
      r.value = value;
    }
    type && (r.type = type);
    if (placeholder) {
      r.placeholder = placeholder;
    } else {
      r._addPlaceholder(type, label);
    }
    return r;
  }

  _addPlaceholder(type, label) {
    switch (type) {
      case VariableType.SELECT:
      case VariableType.DATE:
      case VariableType.DATETIME:
      case VariableType.DATERANGE:
      case VariableType.DATETIMERANGE:
      case VariableType.CASCADER:
      case VariableType.SELECTED_INPUT:
        this.placeholder = `请选择${label}`;
        break;
      default:
        this.placeholder = `请输入${label}`;
        break;
    }
    return this;
  }

  // 设置options
  setOptions(options) {
    this.options = options;
    return this;
  }

  setKeyAndValue(key, value) {
    this[key] = value;
    return this;
  }

  setValue(value) {
    if (this.type === VariableType.SWITCH) {
      this.value = value == 0 ? false : true;
    } else {
      this.value = value;
    }
    return this;
  }

  setClearable() {
    this.clearable = true;
    return this;
  }
}
