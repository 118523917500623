<template>
  <div class="checkbox-group">
    <el-checkbox v-if="checkAllVisible" :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange" :disabled="disabled"
      >全选</el-checkbox
    >
    <el-checkbox-group v-model="checked" @change="handleCheckedChange" :disabled="disabled">
      <el-checkbox v-for="(item, index) in options" :label="item.value" :key="index">{{ item.label }}</el-checkbox>
    </el-checkbox-group>
  </div>
</template>

<script>
export default {
  name: "CheckboxGroup",
  model: {
    prop: "value",
    event: "change"
  },
  props: {
    options: {
      type: Array,
      default: () => []
    },
    value: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    },
    checkAllVisible: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      checked: this.value,
      checkAll: false,
      isIndeterminate: false
    };
  },
  watch: {
    value(newData) {
      this.checked = newData;
    },
    checked(newData) {
      this.$emit("change", newData);
    }
  },
  methods: {
    handleCheckAllChange(val) {
      let _options = [];
      this.options.forEach((item) => {
        _options.push(item.value);
      });
      this.checked = val ? _options : [];
      this.isIndeterminate = false;
    },
    handleCheckedChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.options.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.options.length;
    }
  }
};
</script>

<style lang="scss" scoped></style>
