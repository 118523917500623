import request from "@/plugins/axios";
import requestDownload from "@/plugins/axiosDownload";

export const ApiBuilding = {
  // 分页
  page(data) {
    return request({
      url: "/homeauto-center-device/device/web/realestate/page",
      method: "post",
      data
    });
  },
  // 删除
  remove(id) {
    return request({
      url: `/homeauto-center-device/device/web/realestate/delete/${id}`,
      method: "post"
    });
  },
  // 查看属性详情
  detail(id) {
    return request({
      url: `/homeauto-center-device/device/web/attribute-dic/get/detail/${id}`,
      method: "get"
    });
  },
  // 新增/修改
  addOrUpdate(data) {
    return request({
      url: `/homeauto-center-device/device/web/realestate/addOrUpdate`,
      method: "post",
      data
    });
  },
  // 楼盘-项目-楼栋级联数据，用户已绑定
  listByUser(params) {
    return request({
      url: `/homeauto-center-device/device/web/realestate/cascadeList/all`,
      method: "get",
      params
    });
  },
  // 楼盘-项目-楼栋级联数据，全部
  list(params) {
    return request({
      url: `/homeauto-center-device/device/web/realestate/cascadeList/all/new`,
      method: "get",
      params
    });
  },
  downloadDataTemplate(params) {
    return request({
      url: `/homeauto-center-device/device/web/family/import/download/data-collect`,
      method: "get",
      params,
      responseType: "blob"
    });
  },
  downloadConfigTemplate(realestateId) {
    return requestDownload({
      url: `/homeauto-center-device/device/web/realestate/download/screen-template`,
      method: "get",
      params: {
        realestateId
      }
    });
  },
  // 绑定项目
  bindProject(data) {
    return request({
      url: `/homeauto-center-device/device/separate/bind/project/by/user`,
      method: "post",
      data
    });
  },
  // 已绑定项目
  checkedProject(userId) {
    return request({
      url: `/homeauto-center-device/device/separate/project-ids/by/user-id/${userId}`,
      method: "get"
    });
  }
};

export const ApiProject = {
  list(data) {
    return request({
      url: "/homeauto-center-device/device/web/project/page2",
      method: "post",
      data
    });
  },
  details(projectId) {
    return request({
      url: `/homeauto-center-device/device/web/project/detail/${projectId}`,
      method: "get"
    });
  },
  addOrUpdate(data) {
    return request({
      url: "/homeauto-center-device/device/web/project/addOrUpdate",
      method: "post",
      data
    });
  },
  del(id) {
    return request({
      url: `/homeauto-center-device/device/web/project/delete/${id}`,
      method: "post"
    });
  },

  // 获取所有的楼盘列表
  buildingsOptionsByUser() {
    return request({
      url: "/homeauto-center-device/device/web/realestate/get/filter/realestates",
      method: "get"
    });
  },
  // 项目类型options
  projectsTypeOptions() {
    return request({
      url: "/homeauto-center-device/device/web/project/types",
      method: "get"
    });
  },
  // 根据楼盘id获取楼盘开发商和地址信息
  getDeveloperAndAddress(id) {
    return request({
      url: `/homeauto-center-device/device/web/realestate/get/developer-path/${id}`,
      method: "get"
    });
  },
  // 项目状态
  projectStatusOptions() {
    return request({
      url: `/homeauto-center-device/device/web/project/status`,
      method: "get"
    });
  },
  // 系统项目下拉
  systemProjectOptions() {
    return request({
      url: `/homeauto-center-device/device/web/sys-produt/select-list/`,
      method: "get"
    });
  },
  // 项目连接方式
  projectConnectionMode() {
    return request({
      url: `/homeauto-center-device/device/web/project/get/interact-type/select`,
      method: "get"
    });
  },
  // 485连接设备列表
  connectDeviceOptions() {
    return request({
      url: `/homeauto-center-device/device/web/project/get/connect-device/select`,
      method: "get"
    });
  },
  // 离线看板展示
  offlineKanbanFlagOpitons() {
    return request({
      url: `/homeauto-center-device/device/web/project/get/offline-kanban-flag/select`,
      method: "get"
    });
  }
};

export const ApiHouseTemplate = {
  // 根据项目id获取户型列表
  list(id) {
    return request({
      url: `/homeauto-center-device/device/web/house-template/list/${id}`,
      method: "get"
    });
  },
  add(data) {
    return request({
      url: "/homeauto-center-device/device/web/house-template/add",
      method: "post",
      data
    });
  },
  update(data) {
    return request({
      url: "/homeauto-center-device/device/web/house-template/update",
      method: "post",
      data
    });
  },
  del(data) {
    return request({
      url: "/homeauto-center-device/device/web/house-template/delete",
      method: "post",
      data
    });
  },
  // 户型楼层类型下拉列表获取
  typeOptions() {
    return request({
      url: `/homeauto-center-device/device/web/house-template/status`,
      method: "get"
    });
  },

  // 房间列表
  roomList(id) {
    return request({
      url: `/homeauto-center-device/device/web/house-template/list/room/${id}`,
      method: "get"
    });
  },
  addRoom(data) {
    return request({
      url: "/homeauto-center-device/device/web/house-template/add/room",
      method: "post",
      data
    });
  },
  updateRoom(data) {
    return request({
      url: "/homeauto-center-device/device/web/house-template/update/room",
      method: "post",
      data
    });
  },
  delRoom(data) {
    return request({
      url: "/homeauto-center-device/device/web/house-template/delete/room",
      method: "post",
      data
    });
  },
  // 获取房间类型下拉选项
  roomTypeOptions() {
    return request({
      url: `/homeauto-center-device/device/web/house-template/get/room/types`,
      method: "get"
    });
  },
  // 模板下载
  downloadTemplate() {
    return request({
      url: "/homeauto-center-device/device/web/screen-init/point/template/export",
      method: "get",
      responseType: "blob"
    });
  },
  // 点位数据导入
  importPoint(data, projectId, templateId) {
    return request({
      url: `/homeauto-center-device/device/web/screen-init/point/template/import/${projectId}/${templateId}`,
      method: "post",
      data
    });
  },
  // 配置导入历史记录
  importHistory(templateId) {
    return request({
      url: `/homeauto-center-device/device/web/screen-init/point/template/list/${templateId}`,
      method: "get"
    });
  },
  // 获取温度范围
  getTempRangeConfig(templateId) {
    return request({
      url: `/homeauto-center-device/device/web/settings/get-temp-range-config/${templateId}`,
      method: "get"
    });
  },
  // 设置温度范围
  setTempRangeConfig({ templateId, data }) {
    return request({
      url: `/homeauto-center-device/device/web/settings/save-temp-range-config/${templateId}`,
      method: "post",
      data
    });
  }
};

// 户型-房间-设备
export const ApiRoomDevice = {
  // 房间中的设备
  list(roomId) {
    return request({
      url: `/homeauto-center-device/device/web/house-template/get/device/list/${roomId}`,
      method: "get"
    });
  },
  add(data) {
    return request({
      url: "/homeauto-center-device/device/web/house-template/add/device",
      method: "post",
      data
    });
  },
  update(data) {
    return request({
      url: "/homeauto-center-device/device/web/house-template/update/device",
      method: "post",
      data
    });
  },
  del(data) {
    return request({
      url: "/homeauto-center-device/device/web/house-template/delete/device",
      method: "post",
      data
    });
  },
  productOptions(categoryId) {
    return request({
      url: `/homeauto-center-device/device/web/product/get/category/products/${categoryId}`,
      method: "get"
    });
  },
  systemProductCategory(sysPid) {
    return request({
      url: `/homeauto-center-device/device/web/sys-produt/category/list/${sysPid}`,
      method: "get"
    });
  },
  systemProduct(params) {
    return request({
      url: `/homeauto-center-device/device/web/sys-produt/get/products`,
      method: "get",
      params
    });
  }
};

// 户型-房间-场景
export const ApiRoomScene = {
  list(templateId) {
    return request({
      // url: `/homeauto-center-device/device/web/house-template/scene/get/list/${templateId}`,
      url: `/homeauto-center-device/device/web/house-template/scene/v1/get/list/${templateId}`,
      method: "get"
    });
  },
  add(data) {
    return request({
      url: "/homeauto-center-device/device/web/house-template/scene/v1/add",
      method: "post",
      data
    });
  },
  update(data) {
    return request({
      url: "/homeauto-center-device/device/web/house-template/scene/v1/update",
      method: "post",
      data
    });
  },
  swtichCommonFlag(sceneId) {
    return request({
      url: `/homeauto-center-device/device/web/house-template/scene/switch-common-flag/${sceneId}`,
      method: "get"
    });
  },
  del(data) {
    return request({
      url: "/homeauto-center-device/device/web/house-template/scene/v1/delete",
      method: "post",
      data
    });
  },
  detail(sceneId) {
    return request({
      url: `/homeauto-center-device/device/web/house-template/scene/v1/detail/${sceneId}`,
      method: "post"
    });
  },
  allFloorRoomDeviceList(templateId) {
    return request({
      url: `/homeauto-center-device/device/web/house-template/scene/get/device/v1/list/${templateId}`,
      method: "get"
    });
  },
  allFloorRoomDeviceList2(templateId) {
    return request({
      url: `/homeauto-center-device/device/web/house-template/scene/get/cond-device/v1/list/${templateId}`,
      method: "get"
    });
  },
  floorAndRoomOptions(templateId) {
    return request({
      // url: `/homeauto-center-device/device/web/house-template/scene/get/floor-room/list/${templateId}`,
      url: `/homeauto-center-device/device/web/house-template/scene/get/floor-room/v1/list/${templateId}`,
      method: "get"
    });
  }
};

export const ApiOpLogging = {
  // 根据项目id获取户型列表
  list(data) {
    return request({
      url: `/homeauto-center-device/device/web/project-operation-log/page`,
      method: "post",
      data
    });
  }
};
export const ApiScreenConfig = {
  tree(projectId) {
    return request({
      url: `/homeauto-center-device/device/web/screen-version/screen-config/${projectId}`,
      method: "get"
    });
  }
};

export const ApiBuildingSetting = {
  list(projectId) {
    return request({
      url: `/homeauto-center-device/device/web/family/project/total/${projectId}`,
      method: "post"
    });
  },
  remove(data) {
    return request({
      url: `/homeauto-center-device/device/web/family/project/building/delete`,
      method: "post",
      data
    });
  },
  getTemplateList(projectId) {
    return request({
      url: `/homeauto-center-device/device/web/family/list/tempalte/${projectId}`,
      method: "get"
    });
  },
  add(data) {
    return request({
      url: `/homeauto-center-device/device/web/family/add`,
      method: "post",
      data
    });
  },
  addBatch(data) {
    return request({
      url: `/homeauto-center-device/device/web/family/add-batch`,
      method: "post",
      data
    });
  },
  getBuilds(projectId) {
    return request({
      url: `/homeauto-center-device/device/web/family/get/builds/${projectId}`,
      method: "get"
    });
  },
  // 批量导入地址
  importIpMac(data, projectId) {
    return request({
      url: `/homeauto-center-device/device/web/excel/import/ip-mac/${projectId}`,
      method: "post",
      data
    });
  },
  // 模板下载
  downloadTemplate(projectId) {
    return request({
      url: "/homeauto-center-device/device/web/excel/export/template/ip-mac",
      method: "get",
      params: { projectId },
      responseType: "blob"
    });
  },
  familyCodeChangeTemplate(projectId) {
    return request({
      url: `/homeauto-center-device/device/web/family/manager/family-code-change/template`,
      method: "get",
      responseType: "blob",
      params: {
        projectId
      }
    });
  },
  familyCodeChangeUpload(data) {
    return request({
      url: "/homeauto-center-device/device/web/family/manager/family-code-change/upload",
      method: "post",
      data
    });
  },
  familyCodeChangeSave(data) {
    return request({
      url: "/homeauto-center-device/device/web/family/manager/family-code-change/import",
      method: "post",
      data
    });
  }
};

export const ApiBuildingSettingDetail = {
  list(data) {
    return request({
      url: `/homeauto-center-device/device/web/family/list/page`,
      method: "get",
      params: data
    });
  },
  changeHouseTempalte(data) {
    return request({
      url: `/homeauto-center-device/device/web/family/update/templateId`,
      method: "post",
      data
    });
  },
  del(data) {
    return request({
      url: `/homeauto-center-device/device/web/family/delete-batch`,
      method: "post",
      data
    });
  },
  floorOptions(params) {
    return request({
      url: `/homeauto-center-device/device/web/family/select/floor`,
      method: "get",
      params
    });
  },
  unitOptions(params) {
    return request({
      url: `/homeauto-center-device/device/web/family/select/unit`,
      method: "get",
      params
    });
  },
  // 配置导出
  downloadConfig(familyId) {
    return request({
      url: `/homeauto-center-device/device/web/screen-init/point/template/config/${familyId}`,
      method: "get",
      responseType: "blob"
    });
  }
};
