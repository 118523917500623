/*
 * @Author: hongyan.yang
 * @Date: 2020-04-22 15:07:31
 * @LastEditors: zhongyuanrui
 * @LastEditTime: 2021-04-12 10:26:40
 * @FilePath: \homeauto-web-v3.0\src\utils\util.js
 */
import moment from "moment";

export function isIE() {
  const bw = window.navigator.userAgent;
  const compare = (s) => bw.indexOf(s) >= 0;
  const ie11 = (() => "ActiveXObject" in window)();
  return compare("MSIE") || ie11;
}
// 获取数据类型
export const getObjType = (obj) => {
  var toString = Object.prototype.toString;
  var map = {
    "[object Boolean]": "boolean",
    "[object Number]": "number",
    "[object String]": "string",
    "[object Function]": "function",
    "[object Array]": "array",
    "[object Date]": "date",
    "[object RegExp]": "regExp",
    "[object Undefined]": "undefined",
    "[object Null]": "null",
    "[object Object]": "object"
  };
  if (obj instanceof Element) {
    return "element";
  }
  return map[toString.call(obj)];
};
/**
 * 对象深拷贝
 */
export const deepClone = (data) => {
  var type = getObjType(data);
  var obj;
  if (type === "array") {
    obj = [];
  } else if (type === "object") {
    obj = {};
  } else {
    //不再具有下一层次
    return data;
  }
  if (type === "array") {
    for (var i = 0, len = data.length; i < len; i++) {
      obj.push(deepClone(data[i]));
    }
  } else if (type === "object") {
    for (var key in data) {
      obj[key] = deepClone(data[key]);
    }
  }
  return obj;
};

/**
 * 找出两个数组的差集
 * @param {Array} arr1
 * @param {Array} arr2
 * @returns {Array}
 */
export const findDifferenceByArray = (arr1, arr2) => {
  return arr1.filter(function (item) {
    return !arr2.includes(item);
  });
};
/**
 * 找出两个数组的交集
 * @param {Array} arr1
 * @param {Array} arr2
 * @returns {Array}
 */
export const findCommonByArray = (arr1, arr2) => {
  return arr1.filter(function (item) {
    return arr2.includes(item);
  });
};

// 下载
export function downLoad(content, filename = "") {
  //filename 文件名，content 下载的内容
  if (!filename) {
    filename = moment(new Date()).format("YYYYMMDDHHmmss");
  }
  let aLink = document.createElement("a");
  let blob = new Blob([content], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" //文件类型
  });
  aLink.download = filename;
  aLink.href = URL.createObjectURL(blob);
  aLink.click();
  URL.revokeObjectURL(blob);
}

export function treeToArray(tree, arr) {
  tree.forEach((item) => {
    let _temp = deepClone(item);
    arr.push(_temp);
    if (item.children) {
      treeToArray(item.children, arr);
    }
  });
}
