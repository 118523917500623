/*
 * @Description: 异步路由
 * @Author: hongyan.yang
 * @LastEditors: hongyan.yang
 * @FilePath: /household/src/store/modules/async-router.js
 */
import { generatorDynamicRouter } from "@/router/generator-routers";

export default {
  state: {
    addRouters: []
  },
  mutations: {
    SET_ROUTERS: (state, routers) => {
      state.addRouters = routers;
    }
  },
  actions: {
    GenerateRoutes({ commit }) {
      return new Promise((resolve) => {
        generatorDynamicRouter().then((routers) => {
          commit("SET_ROUTERS", routers);
          resolve();
        });
      });
    }
  }
};
