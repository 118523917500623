<template>
  <div style="position: relative">
    <el-upload
      v-if="!fileImg"
      list-type="picture-card"
      :action="uploadUrl"
      :file-list="fileList"
      :headers="headers"
      :on-success="handleUploadImgSuccess"
      :on-error="handleUploadImgError"
      :before-upload="beforeUpload"
    >
      <i slot="default" class="el-icon-plus"></i>
    </el-upload>
    <div class="el-upload-list--picture-card" v-else>
      <div class="el-upload-list__item">
        <img class="el-upload-list__item-thumbnail" :src="fileImg" alt="" />
        <span class="el-upload-list__item-actions">
          <span class="el-upload-list__item-delete" @click="handleRemove">
            <i class="el-icon-delete"></i>
          </span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    img: {}
  },
  data() {
    return {
      fileList: [],
      fileImg: "",
      headers: { Authorization: this.$ls.get("token") },
      uploadUrl: process.env.VUE_APP_API_BASE_URL + "/homeauto-center-file/file/upload/normal"
    };
  },
  watch: {
    img() {
      if (this.img) {
        this.fileImg = this.img;
        this.fileList = [{ url: this.img }];
      }
    }
  },
  computed: {},
  mounted() {
    if (this.img) {
      this.fileImg = this.img;
      this.fileList = [{ url: this.img }];
    }
  },
  methods: {
    beforeUpload() {},
    handleChange(file, fileList) {
      if (fileList.length > 1) {
        fileList.splice(0, 1);
      }
    },
    handleUploadImgSuccess(res) {
      if (res && res.success) {
        this.fileImg = res.result.url;
        this.fileList = [{ url: res.result.url }];
        this.$emit("update:img", this.fileImg);
      }
    },
    handleUploadImgError() {},
    handleRemove() {
      this.fileImg = "";
      this.fileList = [];
      this.$emit("update:img", "");
    }
  }
};
</script>

<style lang="scss" scoped>
/deep/ .el-upload-list__item {
  border: none !important;
  border-radius: 0 !important;
}
</style>
