/*
 * @Description:
 * @Author: hongyan.yang
 * @LastEditors: hongyan.yang
 * @FilePath: /household/src/permission.js
 */
import router from "./router";
import { setDocumentTitle } from "./utils/domUtil";
import Vue from "vue";
import store from "./store";
import NProgress from "nprogress";
import "nprogress/nprogress.css"; //这个样式必须引入
import { Message } from "element-ui"; // progress bar
import { initKeepAliveList } from "./router/keepAlive";

const WHITE_LIST = ["login", "resetPassword"];
const DEAFULT_ROUTE_PATH = "/dashboard";
const LOGIN_ROUTE_PATH = "/user/login";

NProgress.configure({ showSpinner: false }); // NProgress Configuration

router.beforeEach((to, from, next) => {
  NProgress.start(); // start progress bar
  setDocumentTitle(to.meta.title);
  if (Vue.ls.get("token")) {
    /* has token */
    if (to.path === LOGIN_ROUTE_PATH) {
      next({ path: DEAFULT_ROUTE_PATH });
      NProgress.done();
    } else {
      if (!store.getters.userInfo) {
        store
          .dispatch("getUserInfo")
          .then(() => {
            store.dispatch("GenerateRoutes").then(() => {
              // 根据roles权限生成可访问的路由表
              // 动态添加可访问路由表
              router.addRoutes(store.getters.addRouters);
              // 请求带有 redirect 重定向时，登录自动重定向到该地址
              const redirect = decodeURIComponent(from.query.redirect || to.path);
              if (to.path === redirect) {
                // hack方法 确保addRoutes已完成 ,set the replace: true so the navigation will not leave a history record
                next({ ...to, replace: true });
              } else {
                // 跳转到目的路由
                next({ path: redirect });
              }
            });
          })
          .catch(() => {
            Message.error("请求用户信息失败，请重试");
            // store.dispatch("logout").then(() => {
            //   next({ path: LOGIN_ROUTE_PATH, query: { redirect: to.fullPath } });
            // });
          });
      } else {
        next();
      }
    }
  } else {
    if (WHITE_LIST.includes(to.name)) {
      // 在免登录白名单，直接进入
      console.log("在免登录白名单，直接进入");
      next();
    } else {
      // next({ path: LOGIN_ROUTE_PATH, query: { redirect: to.fullPath } });
      next({ path: LOGIN_ROUTE_PATH });
      NProgress.done(); // if current page is login will not trigger afterEach hook, so manually handle it
    }
  }
});

router.afterEach((to, from) => {
  initKeepAliveList(to, from);
  NProgress.done(); // finish progress bar
});
