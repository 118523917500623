<!--
 * @Description: 
 * @Author: hongyan.yang
 * @LastEditors: zhongyuanrui
 * @FilePath: \homeauto-web-v3.0\src\components\sidebar\SidebarItem.vue
-->
<template>
  <fragment>
    <template v-if="!item.children">
      <el-menu-item :index="item.path" @click="toOtherPage(item)">
        <img v-if="item.meta.icon" :src="item.meta.icon" style="margin-right: 6px" />
        <span :class="item.meta.icon ? '' : 'zw'">{{ item.meta.title }}</span>
      </el-menu-item>
    </template>

    <el-submenu v-else ref="subMenu" :index="item.path" popper-append-to-body>
      <template slot="title">
        <img v-if="item.meta.icon" :src="item.meta.icon" style="margin-right: 6px" />
        <span :class="item.meta.icon ? '' : 'zw'">{{ item.meta.title }}</span>
      </template>
      <sidebar-item v-for="child in item.children" :key="child.path" :item="child" />
    </el-submenu>
  </fragment>
</template>

<script>
export default {
  name: "SidebarItem",
  props: {
    item: {
      type: Object,
      require: true
    }
  },
  methods: {
    toOtherPage(item) {
      this.$router.push(item.path);
    }
  }
};
</script>

<style lang="scss" scoped>
.el-menu-item.is-active {
  background: #071414 !important;
}
.el-submenu__title i,
.el-menu-item i {
  color: #fff;
}
.zw {
  display: inline-block;
  padding-left: 10px;
}
</style>
