/*
 * @Description: 基础form
 * @Author: hongyan.yang
 * @LastEditors: hongyan.yang
 * @FilePath: /household/src/components/form/formProperty.js
 */
import { VariableType } from "../baseInput/baseInputProperty";

export class formProperty {
  labelWidth = "100px";
  // size = "small"; //medium / small / mini
  items = [];

  static Init() {
    let r = new this();
    return r;
  }
  // 设置baseinput宽度
  setInputWidth(width) {
    this.items.forEach((item) => {
      item.width = width;
    });
  }

  setItems(items) {
    this.items = items;
  }

  // 获取inputs的值，返回object
  // showNull 是否要将空值的key放到object中
  getItemsValue(showNull = false) {
    let o = {};
    this.items.forEach((item) => {
      if (showNull || (item.value !== "" && item.value !== undefined)) {
        o[item.variable] = item.value;
      }
      if (item.type === VariableType.SWITCH) {
        o[item.variable] = item.value ? 1 : 0;
      }

      // if (showNull) {
      //   // 布尔值特殊处理
      //   if (item.type === VariableType.SWITCH) {
      //     o[item.variable] = item.value ? 1 : 0;
      //   } else {
      //     o[item.variable] = item.value;
      //   }
      // }
    });
    return o;
  }

  setItemsValue(values) {
    for (const k in values) {
      this.items.forEach((item) => {
        if (item.variable === k) {
          item.setValue(values[k]);
        }
      });
    }
  }

  setOptions(variable, options) {
    this.items.find((item) => {
      if (item.variable === variable) {
        item.setOptions(options);
        return true;
      }
      return false;
    });
  }
  setKeyAndValue(variable, key, value) {
    this.items.find((item) => {
      if (item.variable === variable) {
        item.setKeyAndValue(key, value);
        return true;
      }
      return false;
    });
  }

  setClearable(variables) {
    let isArray = Array.isArray(variables);
    if (isArray) {
      variables.forEach((variable) => {
        let item = this.items.find((item) => item.variable === variable);
        item && item.setClearable();
      });
    } else {
      let item = this.items.find((item) => item.variable === variables);
      item && item.setClearable();
    }
    return this;
  }

  setUniqueWidth(variable, width) {
    let item = this.items.find((item) => item.variable === variable);
    item && (item.width = width);
    return this;
  }
}
