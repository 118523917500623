import request from "@/plugins/axios";

export const ApiUser = {
  login(params) {
    params.client_id = "homeauto";
    params.client_secret = "homeauto";
    params.plat = 1;
    return request({
      url: "/homeauto-center-oauth/login/web",
      method: "post",
      params
    });
  },
  logout() {
    return request({
      url: "/homeauto-center-oauth/auth/sys-user/logout",
      method: "get"
    });
  },
  getTelCode(params) {
    return request({
      url: "/homeauto-center-oauth/auth/sys-user/send/verycode",
      method: "get",
      params
    });
  },
  getEmailCode(params) {
    return request({
      url: "/homeauto-center-oauth/auth/sys-user/send/email/verycode",
      method: "get",
      params
    });
  },
  checkCode(params) {
    return request({
      url: "/homeauto-center-oauth/auth/sys-user/check/code",
      method: "get",
      params
    });
  },
  changePassword(data) {
    return request({
      url: "/homeauto-center-oauth/auth/sys-user/forgetPassword",
      method: "post",
      data
    });
  },
  getUserInfo() {
    return request({
      url: "/homeauto-center-oauth/auth/sys-user/userinfo/buttons/complex",
      method: "get"
    });
  },
  // 获取用户路由
  getCurrentUserNav() {
    return request({
      url: "/homeauto-center-oauth/auth/sys-permission/user/menu/list",
      method: "get"
    });
  }
};
