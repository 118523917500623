const getters = {
  token: (state) => state.user.token,
  userInfo: (state) => state.user.info,
  roleIds: (state) => state.user.roles.map((item) => item.id),
  addRouters: (state) => state.permission.addRouters,
  sidebarOpen: (state) => state.app.sidebarOpen,
  fullScreenLoading: (state) => state.app.fullScreenLoading,
  breadcrumbs: (state) => state.app.breadcrumbs,
  keepAliveMap: (state) => state.app.keepAliveMap,
  keepAliveList: (state) => Object.keys(state.app.keepAliveMap)
};

export default getters;
