<template>
  <div class="table-search-form">
    <div class="table-search-form-right">
      <el-form
        class="table-search-form-right-form"
        ref="form"
        label-position="left"
        size="small"
        inline
        label-width="auto"
        :model="formData"
      >
        <el-form-item
          v-for="(item, key) in formItems"
          :key="key"
          :label="item.label"
          :prop="key"
          :required="item.required"
          :show-message="item.showMessage"
        >
          <slot v-if="item.slot" :name="key"></slot>
          <template v-else>
            <!-- input -->
            <el-input
              v-if="item.type === 'input'"
              placeholder="请输入"
              clearable
              v-model="formData[key]"
              @change="change(key)"
            >
            </el-input>
            <!-- select -->
            <el-select
              v-else-if="item.type === 'select'"
              placeholder="请选择"
              clearable
              :filterable="true"
              :multiple="item.multiple"
              :collapse-tags="item.multiple"
              :multiple-limit="item.multipleLimit"
              v-model="formData[key]"
              @change="change(key)"
            >
              <el-option
                v-for="selectItem in item.options"
                :key="selectItem.value"
                :label="selectItem.label"
                :value="selectItem.value"
              ></el-option>
            </el-select>
            <!-- date -->
            <el-date-picker
              v-else-if="dateTypes.includes(item.type)"
              clearable
              placeholder="请选择"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              range-separator="-"
              :disabled="item.disabled"
              :value-format="item.valueFormat || 'yyyy-MM-dd'"
              :default-time="item.type === 'datetimerange' ? ['00:00:00', '23:59:59'] : item.defaultTime"
              :type="item.type"
              :picker-options="item.pickerOptions"
              v-model="formData[key]"
              @change="change(key)"
            >
            </el-date-picker>
            <span v-if="dateTypes.includes(item.type) && item.searchpath === '1'"
              >说明：日期选择：起始日期“2022-08-01”-截止日期“2022-08-09”表示查询2022年8月1日-9日时间段的能耗值</span
            >
          </template>
        </el-form-item>
      </el-form>
    </div>
    <div class="table-search-form-left">
      <slot name="handleLeft"></slot>
      <el-button type="primary" size="small" @click="search" v-if="searchVisible">查询</el-button>
      <el-button size="small" @click="onReset" v-if="resetVisible">重置</el-button>
      <el-button v-if="exportVisible" :loading="exportLoading" type="primary" size="small" @click="handleExport"
        >导出</el-button
      >
      <slot name="handleRight"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "TableSearchForm",
  model: {
    prop: "formData",
    event: "change"
  },
  props: {
    formItems: {
      type: Object,
      default: () => ({})
    },
    formData: {
      type: Object,
      default: () => ({})
    },
    searchVisible: {
      type: Boolean,
      default: true
    },
    exportVisible: {
      type: Boolean,
      default: false
    },
    exportLoading: {
      type: Boolean,
      default: false
    },
    resetVisible: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      dateTypes: ["year", "month", "date", "dates", "week", "datetime", "datetimerange", "daterange", "monthrange"]
    };
  },
  methods: {
    change(prop) {
      let formData = this.formData;
      // for (const key in formData) {
      //   if (!formData[key]) {
      //     delete formData[key];
      //   }
      // }
      this.$emit("change", formData);
      this.$emit("change-item", { prop, value: formData[prop] });
    },
    search() {
      this.$emit("search");
    },
    handleExport() {
      this.$emit("handle-export");
    },
    onReset() {
      this.$emit("change", {});
      this.$emit("reset");
    }
  }
};
</script>

<style lang="scss" scoped>
.table-search-form {
  display: flex;
  justify-content: space-between;
  padding: 0 30px;
  // margin: 0 0 10px;
  border-radius: 4px;
  box-shadow: rgba(187, 187, 187, 0.3) 0 0 4px 0;
  background: #fff;
  &-right {
    display: flex;
    align-items: center;
    &-form {
      padding: 10px 0 0;
    }
  }
  &-left {
    display: flex;
    align-items: center;
  }
  ::v-deep .el-form-item {
    margin-bottom: 10px;
  }
  ::v-deep .el-input,
  ::v-deep .el-select {
    width: 200px;
  }
}
</style>
