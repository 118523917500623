import store from "../store";

export const initKeepAliveList = (to, from) => {
  const { pageLevel: toPageLevel } = to.meta;
  const { pageLevel: fromPageLevel } = from.meta;
  let _keepAliveMap = { ...store.getters.keepAliveMap };
  if (toPageLevel) {
    if (fromPageLevel) {
      const { name: fromName } = from.matched.at(-1).components.default;
      if (toPageLevel === 1) {
        console.log("进入菜单页面");
        _keepAliveMap = {};
      } else {
        if (toPageLevel < fromPageLevel) {
          console.log("后退");
          for (const key in _keepAliveMap) {
            if (Object.hasOwnProperty.call(_keepAliveMap, key)) {
              const element = _keepAliveMap[key];
              if (element > toPageLevel) {
                delete _keepAliveMap[key];
              }
            }
          }
        } else if (toPageLevel > fromPageLevel) {
          console.log("前进");
        } else if (toPageLevel === fromPageLevel) {
          console.log("同级跳转");
          delete _keepAliveMap[fromName];
        }
      }
    }
    to.matched.forEach((item) => {
      const { name: itemName } = item.components.default;
      const { keepAlive: itemKeepAlive, pageLevel: itemPageLevel } = item.meta;
      if (itemKeepAlive && !_keepAliveMap[itemName]) {
        _keepAliveMap[itemName] = itemPageLevel;
      }
    });
  }
  store.commit("m_setKeepAlive", _keepAliveMap);
};
