<template>
  <el-aside :width="asideWidth" class="sidebar-container">
    <logo></logo>
    <el-scrollbar wrap-class="scrollbar-wrapper">
      <el-menu
        :default-active="$route.path"
        :collapse="!sidebarOpen"
        :collapse-transition="false"
        mode="vertical"
        background-color="#273939"
        text-color="#fff"
        active-text-color="#fff"
      >
        <sidebar-item v-for="route in navs" :key="route.path" :item="route" />
      </el-menu>
    </el-scrollbar>
  </el-aside>
</template>

<script>
import Logo from "./Logo.vue";
import { mapGetters } from "vuex";
import SidebarItem from "./SidebarItem";

export default {
  name: "Sidebar",
  components: { SidebarItem, Logo },
  computed: {
    ...mapGetters(["addRouters", "sidebarOpen"]),
    asideWidth() {
      if (this.sidebarOpen) {
        return "200px";
      } else {
        return "64px";
      }
    }
  },
  data() {
    return {
      navs: []
    };
  },
  mounted() {
    let routes = this.addRouters.find((e) => e.path === "/");
    this.navs = (routes && routes.children) || [];
  }
};
</script>

<style lang="scss">
.sidebar-container {
  display: flex;
  flex-direction: column;
  transition: width 0.28s;
  height: 100vh;
  overflow: hidden;
  box-shadow: 2px 0px 6px 0px rgba(0, 21, 41, 0.35);
  .scrollbar-wrapper {
    overflow-x: hidden !important;
  }
  .el-scrollbar {
    flex: 1;
    background: rgb(39, 57, 57);
  }
  .el-scrollbar__view > .el-menu {
    border: none;
    padding: 0 0 50px;
  }
}
</style>
