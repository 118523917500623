<!--
 * @Description: 通用查询
 * @Author: hongyan.yang
 * @LastEditors: zhongyuanrui
 * @FilePath: \homeauto-web-v3.0\src\components\form\SearchForm.vue
-->
<template>
  <el-form v-if="model" ref="form" :inline="true" :label-width="model.labelWidth" :size="model.size">
    <el-form-item v-for="(item, idx) in model.items" :key="idx" :label="item.label" :prop="item.variable">
      <base-input :model="item"></base-input>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="submit">查询</el-button>
      <el-button @click="reset" v-if="model.showReset">重置</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import BaseInput from "../baseInput/BaseInput.vue";
import { VariableType } from "../baseInput/baseInputProperty";
import { searchFormProperty, searchFormItemProperty } from "./searchFormProperty";
export default {
  components: { BaseInput },
  name: "SearchForm",
  props: {
    model: {}
  },
  data() {
    return {
      // model: null
    };
  },
  methods: {
    initExample() {
      this.model = searchFormProperty.Init();
      let items = [
        searchFormItemProperty.Init("name1", "aa", VariableType.TEXT, "1"),
        searchFormItemProperty.Init("name2", "bb", VariableType.SWITCH, "1"),
        searchFormItemProperty.Init("name3", "cc", VariableType.TEXT, "1")
      ];
      this.model.setItems(items);
      this.model.setInputWidth("100px");
    },
    submit() {
      this.$emit("search");
    },
    reset() {
      this.model.resetForm();
    }
  }
};
</script>

<style lang="scss" scoped>
.search-form {
  padding: 20px 20px 0;
  border-radius: 4px;
  box-shadow: rgba(187, 187, 187, 0.3) 0 0 4px 0;
  background: #fff;
}
.el-button {
  margin-left: 30px;
  padding: 9px 27px;
}
</style>
