<!--
 * @Description: 
 * @Author: hongyan.yang
 * @LastEditors: zhongyuanrui
 * @FilePath: \homeauto-web-v3.0\src\components\baseInput\BaseInput.vue
-->
<template>
  <div v-if="model" :style="style">
    <el-input
      v-if="model.type === VariableType.TEXT"
      type="text"
      :placeholder="model.placeholder"
      v-model="model.value"
      :clearable="model.clearable"
      @change="$emit('change')"
    >
      <template v-if="model.append" slot="append">{{ model.append }}</template>
    </el-input>

    <el-input
      v-else-if="model.type === VariableType.NUMBER"
      type="number"
      :placeholder="model.placeholder"
      :clearable="model.clearable"
      v-model="model.value"
    >
      <template slot="append" v-if="model.append">{{ model.append }}</template>
    </el-input>

    <el-input
      v-else-if="model.type === VariableType.PASSWORD"
      type="password"
      :placeholder="model.placeholder"
      :clearable="model.clearable"
      v-model="model.value"
    >
    </el-input>

    <el-input
      v-else-if="model.type === VariableType.TEXTAREA"
      type="textarea"
      :placeholder="model.placeholder"
      :clearable="model.clearable"
      v-model="model.value"
      :autosize="{ minRows: 5, maxRows: 10 }"
    >
    </el-input>
    <el-select
      style="width: 100%"
      v-else-if="model.type === VariableType.SELECT"
      @change="$emit('change')"
      :clearable="model.clearable"
      v-model="model.value"
      :placeholder="model.placeholder"
    >
      <el-option v-for="(item, idx) in model.options" :label="item.label" :value="item.value" :key="idx"></el-option>
    </el-select>

    <el-switch v-else-if="model.type === VariableType.SWITCH" v-model="model.value"> </el-switch>

    <el-date-picker v-else-if="model.type === VariableType.DATE" :placeholder="model.placeholder" v-model="model.value">
    </el-date-picker>

    <el-date-picker
      v-else-if="model.type === VariableType.DATETIME"
      v-model="model.value"
      type="datetime"
      format="yyyy-MM-dd HH:mm"
      value-format="yyyy-MM-dd HH:mm:ss"
      :clearable="model.clearable"
      :placeholder="model.placeholder"
    >
    </el-date-picker>
    <el-date-picker
      v-else-if="model.type === VariableType.DATERANGE"
      v-model="model.value"
      :clearable="model.clearable"
      type="daterange"
      value-format="yyyy-MM-dd HH:mm:ss"
      range-separator="至"
      start-placeholder="开始日期"
      end-placeholder="结束日期"
    >
    </el-date-picker>
    <el-date-picker
      v-else-if="model.type === VariableType.DATETIMERANGE"
      v-model="model.value"
      :clearable="model.clearable"
      type="datetimerange"
      value-format="yyyy-MM-dd HH:mm:ss"
      range-separator="至"
      start-placeholder="开始日期"
      end-placeholder="结束日期"
    >
    </el-date-picker>

    <el-cascader
      v-else-if="model.type === VariableType.CASCADER"
      style="width: 100%"
      :options="model.options"
      :clearable="model.clearable"
      :props="model.props"
      v-model="model.value"
      @change="$emit('change')"
    >
    </el-cascader>

    <el-autocomplete
      v-else-if="model.type === VariableType.SELECTED_INPUT"
      :options="model.options"
      :clearable="model.clearable"
      :placeholder="model.placeholder"
      v-model="model.value"
      :fetch-suggestions="querySearch"
      @select="handleSelect"
    >
    </el-autocomplete>
  </div>
</template>

<script>
import { VariableType } from "./baseInputProperty";

export default {
  name: "RInput",
  data() {
    return {
      VariableType: VariableType
    };
  },
  props: {
    model: {
      require: true,
      type: Object
    }
  },
  computed: {
    style() {
      let s = {};
      if (this.model && this.model.width) {
        s.width = this.model.width;
      }
      return s;
    }
  },
  methods: {
    querySearch(queryString, cb) {
      let options = this.model.options;
      let results = options && options.length ? options.filter(this.createFilter(queryString)) : options;
      cb(results);
    },
    createFilter(queryString) {
      return (option) => {
        if (!queryString) {
          return true;
        } else {
          return option.value.indexOf(queryString) === 0;
        }
      };
    },
    handleSelect(e) {
      this.model.realValue = e.label;
    }
  }
};
</script>
