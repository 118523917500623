<!--
 * @Description: 
 * @Author: hongyan.yang
 * @LastEditors: zhongyuanrui
 * @FilePath: \homeauto-web-v3.0\src\components\layouts\BaseListPage.vue
-->
<template>
  <div class="detail-info">
    <search-form
      v-if="searchModel"
      class="search-form"
      :model="searchModel"
      @search="handleCurrentChange(1)"
    ></search-form>
    <slot name="form"></slot>

    <div class="table-info-wrap">
      <div class="table-info">
        <el-button
          class="btn"
          v-if="addBtn"
          type="primary"
          :icon="hideBtnIcon ? '' : 'el-icon-circle-plus-outline'"
          size="small"
          @click="$emit('addItem')"
          >{{ addBtn }}</el-button
        >
        <slot name="button"></slot>

        <base-table
          class="table"
          v-if="tableModel"
          :model="tableModel"
          :spanMethod="spanMethod"
          @handleBtn="handleBtn"
          @selection-change="$emit('selection-change', $event)"
        >
          <template v-slot:expand>
            <slot name="expand"></slot>
          </template>
          <template v-slot:fixedButtons>
            <slot name="fixedButtons"></slot>
          </template>
        </base-table>
        <slot name="table"></slot>
      </div>

      <div class="pagination">
        <el-pagination
          v-if="paginationModel"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          v-bind="paginationModel"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseListPage",
  components: {},
  props: ["tableModel", "paginationModel", "searchModel", "addBtn", "hideBtnIcon", "spanMethod"],
  methods: {
    handleSizeChange(val) {
      if (this.paginationModel) {
        this.paginationModel.pageSize = val;
        this.$emit("update:paginationModel", this.paginationModel);
      }

      this.$emit("change");
    },
    handleCurrentChange(val) {
      if (this.paginationModel) {
        this.paginationModel.currentPage = val;
        this.$emit("update:paginationModel", this.paginationModel);
      }
      this.$emit("change");
    },
    handleBtn({ btn, data }) {
      switch (btn.label) {
        case "删除":
          this.delItem(data.id);
          break;
        default:
          this.$emit("handleBtn", { btn, data });
          break;
      }
    },
    delItem(id) {
      this.$confirm("此操作将永久删除该项, 是否继续?", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true
      })
        .then(() => {
          this.$emit("delItem", id);
        })
        .catch(() => {
          this.$message.info("已取消删除");
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.detail-info {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.btn {
  margin-left: 24px;
  margin-top: 10px;
}
.table-info-wrap {
  flex: 1;
  position: relative;
  margin: 20px 0 0;
  padding-bottom: 60px;
  overflow: auto;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 0px 4px 0px rgba(187, 187, 187, 0.3);
}
.table-info {
  height: 100%;
  overflow-y: auto;
}
.pagination {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 60px;
  text-align: right;
  background: #fff;
  box-shadow: 0px -1px 0px 0px #eeeeee, 0px 0px 4px 0px rgba(187, 187, 187, 0.3);
}
.el-pagination {
  padding-top: 16px;
  padding-right: 60px;
}
::v-deep .el-pager .active {
  background: #03ada2;
  color: #fff;
}
</style>
