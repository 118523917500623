<template>
  <el-dialog
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :title="title"
    :visible.sync="visible"
    width="540px"
    :before-close="handleClose"
  >
    <el-transfer
      filterable
      :filter-method="filterMethod"
      v-model="values"
      :data="options"
      :titles="titles"
      @change="changeItem"
      :render-content="renderFunc"
    >
    </el-transfer>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose" size="small">取 消</el-button>
      <el-button type="primary" @click="submit" size="small" :disabled="loading">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: "DialogTransfer",
  props: {
    title: String,
    visible: Boolean,
    options: {},
    titles: {
      default() {
        return ["功能属性池", "已选产品功能属性"];
      }
    },
    transferValues: {}
  },
  data() {
    return {
      dir: "",
      changeValue: [],
      values: [],
      loading: false,
      waitValue: [],
      renderFunc(h, option) {
        if (option.label.length < 9) {
          return <span>{option.label}</span>;
        }
        return (
          <el-tooltip class="item" effect="dark" content={option.label} placement="top-start">
            <span>{option.label}</span>
          </el-tooltip>
        );
      }
    };
  },
  watch: {
    visible(values) {
      if (values) {
        this.values = this.transferValues;
      }
    }
  },
  methods: {
    filterMethod(query, item) {
      return item.label.indexOf(query) > -1;
    },
    handleClose() {
      this.$emit("update:visible", false);
    },
    changeItem(values, dir, changeValue) {
      this.dir = dir;
      this.changeValue = changeValue;
      this.waitValue.push(...changeValue);
    },
    submit() {
      this.loading = true;
      this.$emit("submit", {
        values: this.values,
        dir: this.dir,
        changeValue: Array.from(new Set(this.waitValue))
      });
      this.$emit("update:visible", false);
      this.tmr = setTimeout(() => {
        this.loading = false;
        clearTimeout(this.tmr);
        this.waitValue = [];
      }, 1000);
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header {
  font-size: 18px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}
::v-deep .el-dialog__footer {
  padding-bottom: 10px;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
}
::v-deep .el-transfer {
  padding-left: 25px;
}
::v-deep .el-transfer__buttons {
  padding: 0 10px;
}
::v-deep .el-transfer__buttons button {
  display: block;
  padding: 6px;
}
::v-deep .el-transfer__button:last-child {
  margin-left: 0;
}
::v-deep .el-checkbox__label {
  font-size: 14px !important;
  color: rgba(0, 0, 0, 0.65) !important;
}
::v-deep .el-transfer-panel__filter .el-input__inner {
  border-radius: 0;
}
</style>
