<template>
  <el-button type="text" class="back-button" @click="$router.go(-1)">
    <img class="back-button-icon" :src="require('../../assets/icon-back.png')" />
    返回上级
  </el-button>
</template>

<script>
export default {
  name: "BackButton"
};
</script>

<style lang="scss" scoped>
.back-button {
  display: flex;
  align-items: center;
  padding: 0;
  &-icon {
    width: 16px;
    height: 16px;
  }
}
</style>
