<template>
  <div class="upload-apk">
    <el-input class="upload-apk-name" :value="name" readonly placeholder="请上传文件"></el-input>
    <el-button
      :type="buttonType"
      size="small"
      class="upload-button"
      :loading="customUploadFunc ? loading : uploadLoading"
      :disabled="disabled"
    >
      {{ label }}
      <input type="file" class="upload-file" @change="upload" :disabled="disabled" />
    </el-button>
  </div>
</template>

<script>
import { apiUploadApk } from "@/api/common";
export default {
  name: "UploadApk",
  model: {
    prop: "value",
    event: "change"
  },
  props: {
    label: {
      type: String,
      default: "上传"
    },
    customUploadFunc: {
      type: Function,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    },
    buttonType: {
      type: String,
      default: "primary"
    },
    uploadBefore: {
      type: Function,
      default: null
    },
    value: {
      type: String,
      default: ""
    },
    fileName: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    },
    uploadApiFunc: {
      type: Function,
      default: null
    }
  },
  data() {
    return {
      uploadLoading: false
    };
  },
  computed: {
    name() {
      return this.fileName ? this.fileName : this.value;
    }
  },
  methods: {
    async upload(e) {
      const { files } = e.target;
      if (this.customUploadFunc) {
        this.customUploadFunc(files);
      } else {
        const form = new FormData();
        Array.from(files).forEach((item) => {
          form.append("file", item);
        });
        let params = {
          name: files[0].name
        };
        this.uploadLoading = true;
        try {
          const res = await (this.uploadApiFunc ? this.uploadApiFunc : apiUploadApk)(form);
          params = {
            ...params,
            ...res
          };
          this.$emit("change", res.url);
          this.$emit("upload-success", params);
        } catch (error) {
          console.log(error);
          this.$emit("upload-error");
        }
        this.uploadLoading = false;
        // 解决上传相同文件无效问题
        e.target.value = "";
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.upload-apk {
  display: flex;
  .upload-button {
    position: relative;
    .upload-file {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      cursor: pointer;
    }
  }
  &-name {
    margin-right: 10px;
  }
}
</style>
