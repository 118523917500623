import { deviceType, deviceEnquire } from "../../utils/enquire.js";

export default {
  state: {
    sidebarOpen: true,
    deviceType: deviceType.DESKTOP,
    fullScreenLoading: false,
    breadcrumbs: [],
    keepAliveMap: {}
  },
  mutations: {
    m_setKeepAlive(state, data) {
      state.keepAliveMap = data;
    },
    TOGGLE_SIDEBAR(state) {
      state.sidebarOpen = !state.sidebarOpen;
    },
    CHECK_ENQUIRE(state) {
      deviceEnquire((type) => {
        switch (type) {
          case deviceType.MOBILE:
            state.sidebarOpen = false;
            break;
          case deviceType.TABLET:
            state.sidebarOpen = false;
            break;
          default:
            state.sidebarOpen = true;
            break;
        }
      });
    },
    OPEN_FULL_SCREEN_LOADING(state) {
      state.fullScreenLoading = true;
    },
    CLOSE_FULL_SCREEN_LOADING(state) {
      state.fullScreenLoading = false;
    },
    SET_BREADCRUMBS(state, breadcrumbs) {
      state.breadcrumbs = breadcrumbs;
    }
  }
};
