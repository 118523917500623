<template>
  <keep-alive :include="keepAliveList">
    <router-view />
  </keep-alive>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "BlankPage",
  computed: {
    ...mapGetters(["keepAliveList"])
  }
};
</script>
