<!--
 * @Description: 页码
 * @Author: zhongyuanrui
 * @LastEditors: zhongyuanrui
 * @FilePath: \supply-chain-front\src\components\table\TablePagination.vue
 -->
<template>
  <el-pagination
    background
    :current-page.sync="pageInfo.pageNum"
    :page-sizes="pageSizes"
    :page-size="pageInfo.pageSize"
    :layout="layout"
    :total="pageInfo.total"
    @size-change="handleSizeChange"
    @current-change="handleCurrentChange"
  >
  </el-pagination>
</template>

<script>
export default {
  name: "TablePagination",
  props: {
    layout: {
      type: String,
      default: "total, sizes, prev, pager, next, jumper"
    },
    pageInfo: {
      type: Object,
      default: () => ({
        pageNum: 1,
        pageSize: 10,
        total: 0
      })
    },
    pageSizes: {
      type: Array,
      default: () => [10, 20, 30, 40, 50]
    }
  },
  methods: {
    handleSizeChange(value) {
      let _pageInfo = this.pageInfo;
      _pageInfo.pageSize = value;
      this.$emit("update:pageInfo", _pageInfo);
      this.$emit("change-page", _pageInfo);
      // this.$router.replace({
      //   path: this.$route.fullPath,
      //   query: {
      //     pageSize: value,
      //     pageNum: _pageInfo.pageNum
      //   }
      // });
    },
    handleCurrentChange(value) {
      let _pageInfo = this.pageInfo;
      _pageInfo.pageNum = value;
      this.$emit("update:pageInfo", _pageInfo);
      this.$emit("change-page", _pageInfo);
      // this.$router.replace({
      //   path: this.$route.fullPath,
      //   query: {
      //     pageNum: value,
      //     pageSize: _pageInfo.pageSize
      //   }
      // });
    }
  }
};
</script>

<style lang="scss" scoped></style>
