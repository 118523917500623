import { BasicLayout } from "@/components/layouts";

export const constantRouterMap = [
  {
    path: "/user/login",
    name: "login",
    component: () => import(/* webpackChunkName: "user" */ "@/views/user/Login")
  },
  {
    path: "/user/resetpassword",
    name: "resetPassword",
    component: () => import(/* webpackChunkName: "user" */ "@/views/user/ResetPassword")
  },
  {
    path: "/404",
    component: () => import(/* webpackChunkName: "fail" */ "@/views/exception/404")
  },
  {
    path: "/detail",
    component: BasicLayout,
    children: [
      {
        path: "/space/building/detail",
        component: () => import(/* webpackChunkName: "space" */ "@/views/space/BuildingDetail"),
        meta: {
          title: "房屋详情",
          breadcrumb: [{ path: "/space/list", title: "空间管理" }, { title: "房屋详情" }],
          showBackButton: true,
          keepAlive: true,
          pageLevel: 2
        }
      },
      {
        path: "/space/building/device/detail",
        component: () => import(/* webpackChunkName: "space" */ "@/views/space/BuildingDeviceDetail"),
        meta: {
          title: "设备详情",
          showBackButton: true,
          keepAlive: true,
          pageLevel: 3
          // breadcrumb: [
          //   { path: "/space/list", title: "空间管理" },
          //   { path: "/space/building/detail", title: "房屋详情" },
          //   { title: "设备详情" }
          // ]
        }
      },
      {
        path: "/space/device/base/info",
        component: () => import(/* webpackChunkName: "space" */ "@/views/space/DeviceBaseInfo"),
        meta: {
          title: "设备基本信息",
          showBackButton: true,
          pageLevel: 4
          // breadcrumb: [
          //   { path: "/space/list", title: "空间管理" },
          //   { path: "/space/building/detail", title: "房屋详情" },
          //   { path: "/space/building/device/detail", title: "设备详情" },
          //   { title: "设备基本信息" }
          // ]
        }
      },
      {
        path: "/building/project",
        component: () => import(/* webpackChunkName: "project" */ "@/views/building/ProjectDetail"),
        meta: {
          title: "项目详情"
        },
        children: [
          {
            path: "/building/projectList/projectDetail/baseInfo",
            component: () => import(/* webpackChunkName: "project" */ "@/views/building/projectDetail/BaseInfo"),
            meta: {
              title: "基本信息",
              showBackButton: true,
              pageLevel: 2
            }
          },
          {
            path: "/building/projectList/projectDetail/houseTemplate",
            component: () => import(/* webpackChunkName: "project" */ "@/views/building/projectDetail/HouseTemplate"),
            meta: {
              title: "户型配置",
              showBackButton: true,
              pageLevel: 2
            }
          },
          {
            path: "/building/projectList/projectDetail/houseTemplate/houseTemplateSetting",
            component: () =>
              import(/* webpackChunkName: "project" */ "@/views/building/projectDetail/HouseTemplateSetting"),
            meta: {
              title: "户型配置详情",
              showBackButton: true,
              pageLevel: 3
            }
          },
          {
            path: "/building/projectList/projectDetail/room/roomDeviceList",
            component: () =>
              import(/* webpackChunkName: "project" */ "@/views/building/projectDetail/room/RoomDeviceList"),
            meta: {
              title: "房间设备详情",
              showBackButton: true,
              pageLevel: 4
            }
          },
          {
            path: "/building/projectList/projectDetail/scene/SceneDetail",
            component: () =>
              import(/* webpackChunkName: "project" */ "@/views/building/projectDetail/scene/SceneDetail"),
            meta: {
              title: "房间场景详情",
              showBackButton: true,
              pageLevel: 3
            }
          },
          {
            path: "/building/projectList/projectDetail/buildingSetting",
            component: () => import(/* webpackChunkName: "project" */ "@/views/building/projectDetail/BuildingSetting"),
            meta: {
              title: "楼房配置",
              showBackButton: true,
              pageLevel: 2
            }
          },
          {
            path: "/building/projectList/projectDetail/buildingSettingDetail",
            component: () =>
              import(/* webpackChunkName: "project" */ "@/views/building/projectDetail/BuildingSettingDetail"),
            meta: {
              title: "楼房详情",
              showBackButton: true,
              pageLevel: 3
            }
          },
          {
            path: "/building/projectList/projectDetail/opLogging",
            component: () => import(/* webpackChunkName: "project" */ "@/views/building/projectDetail/OpLogging"),
            meta: {
              title: "操作日志",
              showBackButton: true,
              pageLevel: 2
            }
          },
          {
            path: "/building/projectList/projectDetail/screenConfig",
            component: () => import(/* webpackChunkName: "project" */ "@/views/building/projectDetail/ScreenConfig"),
            meta: {
              title: "大屏配置",
              showBackButton: true,
              pageLevel: 2
            }
          }
        ]
      }
    ]
  }
];
