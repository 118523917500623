<!--
 * @Description: 
 * @Author: zhongyuanrui
 * @LastEditors: zhongyuanrui
 * @FilePath: \homeauto-web-v3.0\src\components\areaCascader\AreaCascader.vue
-->
<template>
  <el-cascader
    ref="areaCascader"
    placeholder="请选择省市区"
    :value="value"
    :options="options"
    @change="handleChange"
    :disabled="disabled"
  ></el-cascader>
</template>

<script>
import { getAreaList } from "@/api/common";
export default {
  name: "AreaCascader",
  model: {
    prop: "value",
    event: "change"
  },
  props: {
    value: {
      type: [String, Array],
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      options: []
    };
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      getAreaList().then((res) => {
        this.options = res;
      });
    },
    handleChange(value) {
      let item = this.$refs.areaCascader.getCheckedNodes()[0];
      this.$emit("change", value);
      this.$emit("select", item);
    }
  }
};
</script>

<style lang="scss" scoped></style>
