<!--
 * @Description: 弹窗form
 * @Author: hongyan.yang
 * @LastEditors: zhongyuanrui
 * @FilePath: \homeauto-web-v3.0\src\components\form\DialogForm.vue
-->
<template>
  <el-dialog
    v-if="model"
    :title="model.title"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :visible.sync="model.visible"
    :width="model.width || '540px'"
    :before-close="handleClose"
  >
    <el-form :model="ruleForm" :rules="model.rules" ref="ruleForm" :label-width="model.labelWidth" :size="model.size">
      <template v-for="(item, idx) in model.items">
        <el-form-item :key="idx" :label="item.label" :prop="item.variable" v-if="item.show">
          <base-input :model="item"></base-input>
        </el-form-item>
        <slot :name="item.variable"></slot>
      </template>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="handleClose">取 消</el-button>
      <el-button type="primary" size="small" @click="submitForm">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { dialogFormProperty, dialogFormItemProperty } from "./dialogFormProperty";
import { VariableType } from "../baseInput/baseInputProperty";
import BaseInput from "../baseInput/BaseInput.vue";

export default {
  name: "DialogForm",
  components: { BaseInput },
  props: {
    model: {}
  },
  computed: {
    ruleForm() {
      let ruleForm = {};
      if (this.model && this.model.items) {
        this.model.items.forEach((e) => {
          ruleForm[e.variable] = e.value;
        });
      }
      return ruleForm;
    }
  },
  mounted() {
    // this.initExample();
  },
  methods: {
    initExample() {
      this.model = dialogFormProperty.Init();
      let items = [
        dialogFormItemProperty.Init("姓名", "name", VariableType.TEXT, "1"),
        dialogFormItemProperty.Init("性别", "region", VariableType.SWITCH, "1")
      ];
      this.model.rules = this.rules;
      this.model.visible = true;
      this.model.setItems(items);
    },
    submitForm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.$emit("handleSure");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleClose() {
      this.$refs.ruleForm && this.$refs.ruleForm.resetFields();
      this.model.resetForm();
      this.model.visible = false;
      this.$emit("handleClose");
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
  padding-right: 40px;
}
::v-deep .el-dialog__header {
  font-size: 18px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}
::v-deep .el-dialog__footer {
  padding-bottom: 10px;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
}
.el-input,
.el-select {
  width: $input-width;
}
</style>
