<!--
 * @Description: logo
 * @Author: hongyan.yang
 * @LastEditors: hongyan.yang
 * @FilePath: /household/src/components/sidebar/Logo.vue
-->
<template>
  <div class="logo" :class="{ 'logo-small': !sidebarOpen }">智慧云平台</div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "Logo",
  computed: {
    ...mapGetters(["sidebarOpen"])
  }
};
</script>

<style lang="scss" scoped>
.logo {
  height: $header-height;
  line-height: $header-height;
  color: $normal-font-color;
  font-weight: 500;
  box-shadow: 0px 1px 4px 0px rgba(102, 102, 102, 0.25);
  @include primary_bg;
  &-small {
    font-size: 12px;
  }
}
</style>
