/*
 * @Description:
 * @Author: hongyan.yang
 * @LastEditors: zhongyuanrui
 * @FilePath: \homeauto-web-v3.0\src\plugins\baseComponents.js
 */
import Vue from "vue";
import {
  SearchForm,
  BaseTable,
  BasePagination,
  BaseInput,
  BaseButton,
  CommonButton,
  // CircleButton,
  CardTable,
  BaseHeader,
  Breadcrumb,
  BaseListPage,
  DialogForm,
  BaseCard,
  TableButton,
  // ArrowIcon,
  SmallTable,
  DialogTransfer,
  BuildingTree,
  FormDialog,
  AreaCascader,
  treeSelect,
  UploadButton,
  UploadImg
} from "@/components";

Vue.component("search-form", SearchForm);
Vue.component("base-table", BaseTable);
Vue.component("base-pagination", BasePagination);
Vue.component("base-input", BaseInput);
Vue.component("base-button", BaseButton);
Vue.component("common-button", CommonButton);
// Vue.component("circle-button", CircleButton);
Vue.component("card-table", CardTable);
Vue.component("base-header", BaseHeader);
Vue.component("breadcrumb", Breadcrumb);
Vue.component("base-list-page", BaseListPage);
Vue.component("dialog-form", DialogForm);
Vue.component("base-card", BaseCard);
Vue.component("table-button", TableButton);
// Vue.component("arrow-icon", ArrowIcon);
Vue.component("small-table", SmallTable);
Vue.component("dialog-transfer", DialogTransfer);
Vue.component("building-tree", BuildingTree);
Vue.component("form-dialog", FormDialog);
Vue.component("area-cascader", AreaCascader);
Vue.component("tree-select", treeSelect);
Vue.component("upload-button", UploadButton);
Vue.component("upload-img", UploadImg);
