/*
 * @Description: 用户相关
 * @Author: hongyan.yang
 * @LastEditors: hongyan.yang
 * @FilePath: /household/src/store/modules/user.js
 */
import { ApiUser } from "@/api/user";
import { Message } from "element-ui";
import Vue from "vue";

export default {
  state: {
    info: null,
    token: null,
    roles: []
  },
  mutations: {
    SET_USER_INFO(state, data) {
      state.info = data;
      state.roles = data.roles;
    }
  },
  actions: {
    getUserInfo({ commit }) {
      return new Promise((resolve, reject) => {
        ApiUser.getUserInfo()
          .then((res) => {
            if (res.pages && res.pages.length > 0) {
              const role = {};
              role.permissions = res.pages;
              role.permissions.map((per) => {
                if (per.actions && per.actions.length > 0) {
                  const action = per.actions.map((action) => {
                    return action.action;
                  });
                  per.actionList = action;
                } else {
                  per.actionList = [];
                }
              });
              role.permissionList = role.permissions.map((permission) => {
                return permission.permissionCode;
              });
            }
            commit("SET_USER_INFO", res);
            resolve(res);
          })
          .catch(() => reject());
      });
    },
    logout() {
      ApiUser.logout().then(() => {
        Message.success("退出成功");
        Vue.ls.clear();
        location.reload();
      });
    }
  }
};
