"use strict";

import Vue from "vue";
import axios from "axios";
import { Message } from "element-ui";
import { PLATFORM } from "@/config";
import { downLoad } from "@/utils/util";

const _axios = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  timeout: 60 * 1000 // Timeout
});

// 请求拦截
_axios.interceptors.request.use(
  function (config) {
    config.headers.platform = PLATFORM;
    let token = Vue.ls.get("token");
    if (token) {
      config.headers.common["Authorization"] = token;
    }
    config.responseType = "blob";
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

//响应拦截
_axios.interceptors.response.use(
  function (response) {
    return new Promise((resolve, reject) => {
      const { data, headers } = response; //headers
      const fileReader = new FileReader();
      fileReader.readAsText(data);
      fileReader.onloadend = () => {
        // 通过FileReader去判断接口返回是json还是文件流
        if (!headers["content-type"]) {
          Message.error("没有数据");
          resolve();
          return;
        }
        if (headers["content-type"].includes("application/json")) {
          // json
          const { errorMsg } = JSON.parse(fileReader.result);
          Message.closeAll();
          Message.error(errorMsg);
          reject();
        } else {
          // 文件流
          const filename = headers["content-disposition"]
            ? decodeURIComponent(headers["content-disposition"].split("filename=")[1])
            : "";
          downLoad(data, filename);
          Message.success("导出成功");
          resolve();
        }
      };
    });
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default _axios;
