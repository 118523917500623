/*
 * @Description:
 * @Author: hongyan.yang
 * @LastEditors: hongyan.yang
 * @FilePath: /household/src/components/form/searchFormProperty.js
 */
import { baseInputProperty } from "../baseInput/baseInputProperty";
import { formProperty } from "./formProperty";
export class searchFormItemProperty extends baseInputProperty {
  oldValue;
}

export class searchFormProperty extends formProperty {
  size = "small";
  model = {};

  // showReset 是否显示重置按钮
  showReset = false;

  // 重置form值
  resetForm() {
    this.items.forEach((item) => {
      item.value = item.oldValue;
    });
  }

  setItems(items) {
    this.items = items;
    items.forEach((item) => {
      item.oldValue = item.value;
      item.width = "200px";
    });
  }
}
