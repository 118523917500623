/*
 * @Description: 引入插件
 * @Author: hongyan.yang
 * @LastEditors: hongyan.yang
 * @FilePath: /household/src/plugins/index.js
 */
import Vue from "vue";
import Fragment from "vue-fragment";
import Storage from "vue-ls";

import "./axios";
import "./baseComponents.js";

import { MessageBox } from "element-ui";

const storageOptions = {
  namespace: "vuejs__", // key prefix
  name: "ls", // name variable Vue.[ls] or this.[$ls],
  storage: "local" // storage name session, local, memory
};
Vue.use(Storage, storageOptions);
Vue.use(Fragment.Plugin);

Vue.prototype.$delItem = (cb, tip) => {
  MessageBox.confirm(tip || "此操作将永久删除该项, 是否继续?", "警告", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "warning",
    center: true
  })
    .then(() => {
      cb && cb();
    })
    .catch(() => {
      // Message.info("已取消删除");
    });
};
