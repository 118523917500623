<!--
 * @Description: 楼盘列表
 * @Author: zhongyuanrui
 * @LastEditors: zhongyuanrui
 * @FilePath: \homeauto-web-v3.0\src\components\buildingTree\BuildingTree.vue
-->
<template>
  <div class="building-tree">
    <div class="building-tree-search">
      <el-input
        v-model="buildingName"
        placeholder="请输入楼盘名称"
        size="small"
        @blur="initBuildingList"
        @keyup.enter.native="initBuildingList"
      >
        <i slot="suffix" class="el-input__icon el-icon-search" @click="initBuildingList"></i>
      </el-input>
    </div>
    <div class="building-tree-list">
      <el-tree
        ref="tree"
        node-key="id"
        :highlight-current="true"
        :data="buildingList"
        :props="defaultProps"
        :default-expanded-keys="defaultShowNodes"
        @node-click="handleNodeClick"
      >
      </el-tree>
    </div>
  </div>
</template>

<script>
import { ApiBuilding } from "@/api/building";
function formatList(list, parentValue = null) {
  for (let i = 0; i < list.length; i++) {
    list[i].value = String(list[i].value);
    list[i].id = parentValue ? `${parentValue}-${String(list[i].value)}` : String(list[i].value);
    if (list[i].children) {
      formatList(list[i].children, list[i].id);
    }
  }
}
// 递归获取所有数组中第一个元素的value
function getAllValueByFirst(list, values = []) {
  for (let i = 0; i < list.length; i++) {
    if (i === 0) {
      const { value, children } = list[i];
      values.push(value);
      if (!children) {
        return values;
      } else {
        return getAllValueByFirst(children, values);
      }
    }
  }
}
// 根据当前元素找到所有父级的value
function getAllValueByParent(obj, values = []) {
  const { data, parent } = obj;
  if (parent) {
    values.unshift(data.value);
    return getAllValueByParent(parent, values);
  } else {
    return values;
  }
}
export default {
  name: "BuildingTree",
  props: {
    level: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      buildingName: "",
      buildingList: [],
      defaultShowNodes: [], // 这里存放要默认展开的节点 id
      defaultProps: {
        children: "children",
        label: "label"
      }
    };
  },
  created() {
    this.initBuildingList();
  },
  methods: {
    // 初始化楼盘列表
    initBuildingList() {
      let params = {
        name: this.buildingName
      };
      ApiBuilding.listByUser(params).then((res) => {
        this.formatListByLevel(res);
        formatList(res);
        this.buildingList = res;
        this.getCurrentBuilding();
      });
    },
    formatListByLevel(data) {
      if (!this.level) return;
      data.forEach((item) => {
        if (item.children && item.children.length) {
          item.children.forEach((_item) => {
            delete _item.children;
          });
        }
      });
    },
    // 获取当前选中楼盘
    getCurrentBuilding() {
      if (!this.buildingList.length) {
        return;
      }
      let currentNodeKey = null;
      const { keys } = this.$route.query;
      let values = [];
      if (keys) {
        values = keys.split(",");
        currentNodeKey = values.join("-");
      } else {
        values = getAllValueByFirst(this.buildingList);
        currentNodeKey = values.join("-");
        this.setRouteQuery(values);
      }
      this.defaultShowNodes = [currentNodeKey];
      this.$nextTick(() => {
        this.$refs.tree.setCurrentKey(currentNodeKey);
      });
      this.$emit("node-click", values);
    },
    handleNodeClick(data, node) {
      if (!data.children || !data.children.length) {
        let values = getAllValueByParent(node);
        this.setRouteQuery(values);
        this.$emit("node-click", values);
      }
    },
    // 设置路由参数
    setRouteQuery(list) {
      let keys = list.join(",");
      this.$router.replace({
        path: this.$router.history.current.path,
        query: {
          keys
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.building-tree {
  display: flex;
  flex-direction: column;
  width: 208px;
  min-width: 208px;
  margin-right: 18px;
  &-search {
    height: 60px;
    margin: 0 0 2px;
    line-height: 60px;
    text-align: center;
    background: #fff;
    box-shadow: 0px 0px 4px 0px rgba(187, 187, 187, 0.3);
    border-radius: 4px 4px 0px 0px;
    ::v-deep .el-input {
      width: 170px;
    }
  }
  &-list {
    flex: 1;
    padding: 10px 0 30px;
    background: #fff;
    box-shadow: 0px 0px 4px 0px rgba(187, 187, 187, 0.3);
    border-radius: 0px 0px 4px 4px;
    overflow: auto;
  }
}
</style>
