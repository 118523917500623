/*
 * @Description: 响应css媒体查询的轻量级javascript库
 * @Author: hongyan.yang
 * @LastEditors: hongyan.yang
 * @FilePath: /household/src/utils/enquire.js
 */
import enquireJs from "enquire.js";

export const deviceType = {
  DESKTOP: "desktop",
  TABLET: "tablet",
  MOBILE: "mobile"
};

export const deviceEnquire = function (callback) {
  const matchDesktop = {
    match: () => {
      callback && callback(deviceType.DESKTOP);
    }
  };

  const matchTablet = {
    match: () => {
      callback && callback(deviceType.TABLET);
    }
  };

  const matchMobile = {
    match: () => {
      callback && callback(deviceType.MOBILE);
    }
  };

  // screen and (max-width: 1087.99px)
  enquireJs
    .register("screen and (max-width: 576px)", matchMobile)
    .register("screen and (min-width: 576px) and (max-width: 1199px)", matchTablet)
    .register("screen and (min-width: 1200px)", matchDesktop);
};
