<!--
 * @Description: 基础按钮 - 模拟el按钮
 * @Author: hongyan.yang
 * @LastEditors: hongyan.yang
 * @FilePath: /household/src/components/baseButton/BaseButton.vue
-->
<template>
  <button v-if="active" class="btn-active" :style="style" @click="$emit('click')" :loading="loading">
    <slot></slot>
  </button>
  <button v-else class="btn-disabled" :style="style">
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: "BaseButton",
  props: {
    active: {
      default: true
    },
    width: {
      type: String,
      default: "100%"
    },
    height: {
      type: String,
      default: "50px"
    },
    borderRadius: {
      type: String
    },
    loading: {}
  },
  computed: {
    style() {
      let style = {
        height: this.height,
        "border-radius": this.borderRadius || parseInt(this.height) / 2 + "px"
      };
      if (this.width) {
        style.width = this.width;
      }
      return style;
    }
  }
};
</script>

<style lang="scss" scoped></style>
