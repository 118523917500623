<template>
  <el-select
    size="mini"
    multiple
    filterable
    :disabled="disabled"
    v-model="selectedArr"
    :loading="mulSelectLoading"
    :collapse-tags="collapseTags"
    placeholder="请选择"
    @change="changeselect"
    @remove-tag="removeTag"
  >
    <el-option
      label="全选"
      value="全选"
      v-if="selectOptions.length"
      :disabled="!isabledOptionsLength"
      @click.native="selectAll"
    ></el-option>
    <el-option
      v-for="item in selectOptions"
      :key="item.value"
      :label="item.label"
      :value="item.value"
      :disabled="item.disabled"
    ></el-option>
  </el-select>
</template>

<script>
export default {
  name: "MultipleSelect",
  model: {
    prop: "value",
    event: "change"
  },
  props: {
    selectOptions: {
      type: Array,
      default: () => {
        return [];
      }
    },
    // 是否禁用
    disabled: {
      type: Boolean,
      default: false
    },
    // 已选中选项
    value: {
      type: Array,
      default: () => {
        return [];
      }
    },
    mulSelectLoading: {
      type: Boolean,
      default: false
    },
    collapseTags: { type: Boolean, default: true }
  },
  data() {
    return {
      selectedArr: []
    };
  },
  computed: {
    isabledOptionsLength() {
      return this.selectOptions.filter((item) => !item.disabled).length;
    }
  },
  watch: {
    value: {
      handler(newVal) {
        this.selectedArr = newVal;
        if (
          !this.selectedArr.includes("全选") &&
          this.selectedArr.length &&
          this.selectedArr.length === this.isabledOptionsLength
        ) {
          this.selectedArr.unshift("全选");
        }
      },
      immediate: true
    }
  },
  mounted() {},
  methods: {
    selectAll() {
      if (this.selectedArr.length < this.isabledOptionsLength) {
        this.selectOptions.map((item) => {
          !item.disabled && this.selectedArr.push(item.value);
        });
        this.selectedArr.unshift("全选");
      } else {
        this.selectedArr = [];
      }
      this.$emit("change", this.selectedArr);
    },
    changeselect(val) {
      if (!val.includes("全选") && val.length === this.selectOptions.length) {
        this.selectedArr.unshift("全选");
      } else if (val.includes("全选") && val.length - 1 < this.selectOptions.length) {
        this.selectedArr = this.selectedArr.filter((item) => {
          return item !== "全选";
        });
      }
      this.$emit("change", this.selectedArr);
    },
    removeTag(val) {
      if (val === "全选") {
        this.selectedArr = [];
        this.$emit("change", this.selectedArr);
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
