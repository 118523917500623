<!--
 * @Description: 
 * @Author: zhongyuanrui
 * @LastEditors: zhongyuanrui
 * @FilePath: \homeauto-web-v3.0\src\components\smallTable\SmallTable.vue
-->
<template>
  <div class="small-table">
    <div class="small-table-header">
      <div class="small-table-title">{{ title }}</div>
      <div class="small-table-handle" v-if="!disabled">
        <img src="../../assets/attribute/icon-add.png" @click="add" />
        <img src="../../assets/attribute/icon-down.png" @click="down" />
        <img src="../../assets/attribute/icon-up.png" @click="up" />
        <img src="../../assets/attribute/icon-remove.png" @click="remove" />
      </div>
    </div>
    <el-table height="216px" border :data="data">
      <el-table-column v-for="item in columns" :key="item.prop" v-bind="item">
        <template slot-scope="scope">
          <template v-if="!disabled">
            <el-checkbox
              v-if="item.prop === 'sortNo'"
              v-model="scope.row['checked']"
              @change="changeMultipleSelection"
              >{{ scope.row[item.prop] }}</el-checkbox
            >
            <el-input v-else v-model="scope.row[item.prop]" size="mini" placeholder="请输入内容"></el-input>
          </template>
          <template slot-scope="scope" v-else>
            {{ scope.row[item.prop] }}
          </template>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { deepClone, findDifferenceByArray } from "@/utils/util";
export default {
  name: "SmallTable",
  components: {},
  props: {
    columns: {
      type: Array,
      default: () => []
    },
    data: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: "请设置选项"
    }
  },
  data() {
    return {
      // 勾选项
      multipleSelection: []
    };
  },
  computed: {
    // 是否有勾选
    isSelected() {
      return this.multipleSelection && this.multipleSelection.length;
    },
    // 勾选项是否连续
    isContinuation() {
      if (!this.multipleSelection.length) {
        return false;
      }
      let temp = this.multipleSelection[0].sortNo;
      let isContinuation = true;
      for (let i = 0; i < this.multipleSelection.length; i++) {
        const currentSortNo = this.multipleSelection[i].sortNo;
        if (currentSortNo != temp) {
          isContinuation = false;
          break;
        }
        temp++;
      }
      return isContinuation;
    },
    // 表格中第一个勾选的位置
    startIndex() {
      if (!this.multipleSelection.length) {
        return null;
      }
      let startIndex = null;
      this.data.forEach((item, index) => {
        if (this.multipleSelection[0].sortNo === item.sortNo) {
          startIndex = index;
        }
      });
      return startIndex;
    },
    // 表格中最后一个勾选的位置
    endIndex() {
      if (!this.multipleSelection.length) {
        return null;
      }
      let endIndex = null;
      this.data.forEach((item, index) => {
        if (this.multipleSelection[this.multipleSelection.length - 1].sortNo === item.sortNo) {
          endIndex = index;
        }
      });
      return endIndex;
    }
  },
  watch: {
    data() {
      this.changeMultipleSelection();
    }
  },
  methods: {
    changeMultipleSelection() {
      let list = [];
      this.data.forEach((item) => {
        if (item.checked) {
          list.push(item);
        }
      });
      this.multipleSelection = list;
    },
    // 新增
    add() {
      let list = deepClone(this.data) || [];
      let row = {};
      this.columns.forEach((item) => {
        row[item.prop] = "";
      });
      row.sortNo = list.length + 1;
      row.checked = false;
      list.push(row);
      this.$emit("change", list);
    },
    // 下移
    down() {
      if (!this.isSelected) {
        this.$message.warning("请勾选选项");
        return;
      }
      if (!this.isContinuation) {
        this.$message.warning("请勾选连续的选项");
        return;
      }
      if (this.endIndex === this.data.length - 1) {
        this.$message.warning("已经是到低了");
        return;
      }
      let list = deepClone(this.data);
      list.splice(this.endIndex + 2, 0, ...deepClone(this.multipleSelection));
      list.splice(this.startIndex, this.multipleSelection.length);
      list.forEach((item, index) => {
        item.sortNo = index + 1;
      });
      this.$emit("change", list);
    },
    // 上移
    up() {
      if (!this.isSelected) {
        this.$message.warning("请勾选选项");
        return;
      }
      if (!this.isContinuation) {
        this.$message.warning("请勾选连续的选项");
        return;
      }
      if (this.startIndex === 0) {
        this.$message.warning("已经是到顶了");
        return;
      }
      let list = deepClone(this.data);
      list.splice(this.startIndex, this.multipleSelection.length);
      list.splice(this.startIndex - 1, 0, ...deepClone(this.multipleSelection));
      list.forEach((item, index) => {
        item.sortNo = index + 1;
      });
      this.$emit("change", list);
    },
    // 删除
    remove() {
      if (!this.isSelected) {
        this.$message.warning("请勾选选项");
        return;
      }
      let list = findDifferenceByArray(this.data, this.multipleSelection);
      list.forEach((item, index) => {
        item.sortNo = index + 1;
        item.checked = false;
      });
      this.$emit("change", list);
    }
  }
};
</script>

<style lang="scss" scoped>
.small-table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 20px;
  margin: 0 0 14px;
}
.small-table-title {
  font-size: 14px;
  font-weight: 500;
  color: #333333;
}
.small-table-handle {
  display: flex;
  align-items: center;
  img {
    width: 16px;
    height: 16px;
    margin-left: 19px;
    cursor: pointer;
  }
}
</style>
