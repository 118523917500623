<!--
 * @Description: 上传按钮
 * @Author: zhongyuanrui
 * @LastEditors: zhongyuanrui
 * @FilePath: \homeauto-web-v3.0\src\components\upload\UploadButton.vue
-->
<template>
  <el-button :type="buttonType" size="small" class="upload-button" :loading="customUpload ? loading : uploadLoading">
    {{ label }}
    <input class="upload-file" type="file" @change="upload" />
  </el-button>
</template>

<script>
import { apiFileUpload } from "@/api/common";

export default {
  name: "UploadButton",
  props: {
    label: {
      type: String,
      default: "上传"
    },
    customUpload: {
      type: Function,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    },
    buttonType: {
      type: String,
      default: "primary"
    },
    uploadBefore: {
      type: Function,
      default: null
    }
  },
  data() {
    return {
      uploadLoading: false
    };
  },
  methods: {
    async upload(e) {
      const { files } = e.target;
      if (this.customUpload) {
        this.customUpload(files);
      } else {
        const form = new FormData();
        files.forEach((item) => {
          form.append("file", item);
        });
        const params = {};
        this.uploadLoading = true;
        try {
          const res = await apiFileUpload(params, form);
          this.$emit("upload-success", res);
        } catch (error) {
          console.log(error);
          this.$emit("uploadError");
        }
        this.uploadLoading = false;
      }
      // 解决上传相同文件无效问题
      e.target.value = "";
    }
  }
};
</script>

<style lang="scss" scoped>
.upload-button {
  position: relative;
  .upload-file {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }
}
</style>
