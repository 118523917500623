"use strict";

import Vue from "vue";
import axios from "axios";
import { PLATFORM } from "@/config";
import { Message } from "element-ui";

let config = {
  baseURL: process.env.VUE_APP_API_BASE_URL,
  timeout: 60 * 1000 // Timeout
};

function checkResponseResult(data) {
  if (!data.success) {
    if (data.errorCode == 4003) {
      Message.error(data.errorMsg || data.message);
      Vue.ls.clear();
      location.reload();
    } else {
      if (data.errorMsg || data.message) {
        Message.error(data.errorMsg || data.message);
        return Promise.reject();
      } else {
        return Promise.reject(data);
      }
    }
    // return Promise.reject(data);
  }
  return data.result;
}

const _axios = axios.create(config);

_axios.interceptors.request.use(
  function (config) {
    config.headers.platform = PLATFORM;
    let token = Vue.ls.get("token");
    if (token) {
      config.headers.common["Authorization"] = token;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
  function (response) {
    return new Promise((resolve) => {
      const { data } = response;
      // 处理上传文件的返回提示(返回成功时)
      if (
        response.config.responseType == "blob" &&
        response.headers["content-type"].indexOf("application/vnd.ms-excel") == -1
      ) {
        resolve({
          filename: response.headers["content-disposition"]
            ? response.headers["content-disposition"].split("filename=")[1]
            : "",
          blob: data
        });
      } else {
        resolve(checkResponseResult(data));
      }
    });
  },
  function (error) {
    // Do something with response error
    return Promise.reject(error);
  }
);

Plugin.install = function (Vue) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      }
    },
    $axios: {
      get() {
        return _axios;
      }
    }
  });
};

Vue.use(Plugin);

export default _axios;
