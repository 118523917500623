<template>
  <el-button size="small" :plain="plain" :type="type" @click="$emit('click')"><slot></slot></el-button>
</template>

<script>
export default {
  name: "CommonButton",
  props: ["type", "plain"]
};
</script>

<style lang="scss" scoped>
.el-button {
  width: 92px;
}
.el-button--info.is-plain {
  color: #999;
  background: rgba(187, 187, 187, 0.1);
  border-color: rgba(187, 187, 187, 0.1);
}
</style>
