<!--
 * @Description: 基础table
 * @Author: hongyan.yang
 * @LastEditors: zhongyuanrui
 * @FilePath: \homeauto-web-v3.0\src\components\baseTable\BaseTable.vue
-->
<template>
  <el-table
    v-if="model"
    v-bind="model.tableProperty"
    border
    :tree-props="model.treeProps"
    :data="model.data"
    style="margin-top: 10px"
    v-loading="model.vLoading"
    :header-cell-style="{ background: '#fafafa' }"
    :span-method="spanMethod"
    @selection-change="$emit('selection-change', $event)"
  >
    <slot name="expand"></slot>
    <el-table-column v-if="model.selection" type="selection" width="50" :selectable="model.selectable">
    </el-table-column>
    <template v-for="(item, idx) in model.colProperty">
      <el-table-column
        v-if="item.show"
        :key="idx"
        :prop="item.prop"
        :label="item.label"
        :width="item.width"
        :show-overflow-tooltip="item.showOverflowTooltip"
      >
      </el-table-column>
    </template>
    <el-table-column
      fixed="right"
      label="操作"
      header-align="center"
      :width="model.opBtnsWidth || model.opBtns.length * 80"
      v-if="model.opBtns && model.opBtns.length"
    >
      <template slot-scope="scope">
        <table-button
          v-for="(btn, idx) in model.opBtns"
          :key="'btn' + idx"
          @click.native.prevent="handleBtn(btn, scope.row)"
          :type="btn.type"
        >
          {{ btn.label }}
        </table-button>
      </template>
    </el-table-column>
    <slot name="fixedButtons"></slot>
  </el-table>
</template>

<script>
// import { baseTableProperty, colProperty, btnProperty } from "./baseTableProperty";
import TableButton from "../baseButton/TableButton.vue";

export default {
  name: "BaseTable",
  props: {
    model: {},
    spanMethod: {
      type: Function,
      default: null
    }
  },
  components: { TableButton },
  data() {
    return {
      // model: null
    };
  },
  methods: {
    initExample() {
      // let model = new baseTableProperty();
      // model.data = [
      //   {
      //     id: 1,
      //     permissionName: "仪表盘"
      //   },
      //   {
      //     id: 2,
      //     permissionName: "仪表盘"
      //   }
      // ];
      // model.treeProps = { children: "children", hasChildren: "hasChildren" };
      // model.colProperty = [colProperty.Init("菜单名称", "permissionName")];
      // model.opBtns = [btnProperty.Init("添加"), btnProperty.Init("修改"), btnProperty.Init("删除")];
      // this.model = model;
      // console.log(model);
    },
    handleBtn(btn, data) {
      this.$emit("handleBtn", { btn, data });
    }
  }
};
</script>

<style lang="scss" scoped>
.el-pagination {
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: right;
}
</style>
