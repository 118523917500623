<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "app",

  data() {
    return {
      loading: null
    };
  },
  computed: {
    fullScreenLoading() {
      return this.$store.getters.fullScreenLoading;
    }
  },
  watch: {
    fullScreenLoading(open) {
      if (open) {
        this.loading = this.$loading({
          lock: true,
          text: "Loading",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)"
        });
      } else {
        this.loading && this.loading.close();
      }
    }
  },
  mounted() {
    this.$store.commit("CHECK_ENQUIRE");

    // this.$fullScreenLoading;
  }
};
</script>

<style lang="scss">
#app {
  font-family: "PingFang SC", "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  overflow: hidden;
  color: #666;
  font-size: 14px;
}
#nprogress .bar {
  background: #03ada2 !important;
}
.ant-select-dropdown {
  z-index: 20002 !important;
}
</style>
