<template>
  <div class="breadcrumb" v-if="show">
    <el-breadcrumb class="breadcrumb-content" separator=">" v-if="show">
      <transition-group name="breadcrumb">
        <el-breadcrumb-item v-for="(item, index) in levelList" :key="index + 'a'">
          <span v-if="item.redirect === 'noRedirect' || index == levelList.length - 1" class="no-redirect">{{
            item.title
          }}</span>
          <a v-else @click.prevent="handleLink(item)">{{ item.title }}</a>
        </el-breadcrumb-item>
      </transition-group>
    </el-breadcrumb>
    <back-button v-if="showBackButton"></back-button>
  </div>
</template>

<script>
export default {
  name: "Breadcrumb",
  data() {
    return {
      levelList: null
    };
  },
  props: {
    hasLeftPadding: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    breadcrumbs() {
      return this.$store.getters.breadcrumbs;
    },
    show() {
      return this.levelList.length > 0 && !this.$route.meta.hiddenBreadcrumb;
    },
    showBackButton() {
      return this.$route.meta.showBackButton;
    }
  },
  watch: {
    $route() {
      this.getBreadcrumb();
    },
    breadcrumbs() {
      this.getBreadcrumb();
    }
  },
  created() {
    this.getBreadcrumb();
  },
  methods: {
    getBreadcrumb() {
      let breadcrumb = this.$route.meta.breadcrumb || this.breadcrumbs || [];
      if (breadcrumb.length) {
        this.levelList = breadcrumb;
      } else if (!this.$route.meta.hiddenBreadcrumb) {
        let filterRoute = this.$route.matched.filter((item) => item.meta && item.meta.title);
        this.levelList = filterRoute.map((e) => {
          return {
            path: e.path,
            title: e.meta.title
          };
        });
      }
    },
    handleLink(item) {
      const { redirect, path, query } = item;
      if (redirect) {
        this.$router.push(redirect);
        return;
      }
      if (query) {
        this.$router.push({
          path,
          query
        });
      } else {
        this.$router.push(path);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.breadcrumb {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 20px;
  &-content {
    line-height: 1;
    .no-redirect {
      color: #97a8be;
      color: #333;
      cursor: text;
    }
    ::v-deep .el-breadcrumb__inner a {
      font-weight: 500;
      padding: 4px;
      color: rgba(0, 0, 0, 0.45);
    }
    ::v-deep .el-breadcrumb__inner a:hover {
      background: #e5f9f7;
    }
  }
}
</style>
