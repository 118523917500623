<!--
 * @Description: 表格组件
 * @Author: zhongyuanrui
 * @LastEditors: zhongyuanrui
 * @FilePath: \xunyuan-web\src\components\common\table\TableContainer.vue
 -->
<template>
  <div class="table-container" :class="{ 'table-container-shadow': isCard }">
    <div class="table-container-handle-bar" v-if="handleBarVisible">
      <div class="table-container-handle-bar-left">
        <slot name="handleBarLeft"></slot>
      </div>
      <div class="table-container-handle-bar-right">
        <slot name="handleBarRight"></slot>
      </div>
    </div>
    <el-table
      v-if="tableVisible"
      ref="tableContent"
      header-cell-class-name="table-header-cell-style"
      row-key="id"
      :tree-props="{ children: 'children', hasChildren: 'true' }"
      default-expand-all
      :border="border"
      :height="height"
      :max-height="maxHeight"
      :row-style="rowStyle"
      :cell-style="cellStyle"
      :data="tableData"
      v-loading="loading"
      :span-method="spanMethod"
      @filter-change="filterChange"
      @selection-change="handleSelectionChange"
    >
      <el-table-column
        v-if="selectionVisible"
        type="selection"
        width="50"
        align="center"
        fixed="left"
        :selectable="selectable"
      ></el-table-column>
      <el-table-column v-if="indexVisible" type="index" width="50" label="序号" fixed="left"></el-table-column>
      <el-table-column
        v-for="(item, index) in columns"
        :key="index"
        v-bind="item"
        :show-overflow-tooltip="true"
        filter-placement="bottom-start"
      >
        <template slot-scope="scope">
          <slot v-if="item.isSlot" :name="item.prop" :scope="scope"></slot>
          <div v-else-if="item.formatter">{{ item.formatter(scope.row) }}</div>
          <el-link type="primary" @click="item.onLinkFunc(scope.row)" v-else-if="item.isLink">{{
            scope.row[item.prop]
          }}</el-link>
          <span v-else>{{ scope.row[item.prop] }}</span>
        </template>
      </el-table-column>
      <el-table-column v-if="rightMenuVisible" label="操作" :width="rightMenuWidth" fixed="right" align="center">
        <template slot-scope="scope">
          <slot name="rightMenu" :scope="scope"></slot>
        </template>
      </el-table-column>
    </el-table>
    <div class="table-container-footer" v-if="!!pageInfo">
      <table-pagination :pageInfo.sync="pageInfo" @change-page="changePage" />
    </div>
  </div>
</template>

<script>
import TablePagination from "./TablePagination";

export default {
  name: "TableContainer",
  components: { TablePagination },
  props: {
    columns: {
      type: Array,
      default: () => []
    },
    tableData: {
      type: Array,
      default: () => []
    },
    rightMenuVisible: {
      type: Boolean,
      default: true
    },
    rightMenuWidth: {
      type: [Number, String],
      default: "auto"
    },
    defaultMultipleSelection: {
      type: Array,
      default: () => []
    },
    pageInfo: {
      type: Object,
      default: null
    },
    handleBarVisible: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    border: {
      type: Boolean,
      default: true
    },
    height: {
      type: [String, Number],
      default: "-"
    },
    maxHeight: {
      type: [String, Number],
      default: "-"
    },
    selectionVisible: {
      type: Boolean,
      default: false
    },
    indexVisible: {
      type: Boolean,
      default: false
    },
    filterChange: {
      type: Function,
      default: () => {}
    },
    isCard: {
      type: Boolean,
      default: true
    },
    spanMethod: {
      type: Function,
      default: null
    },
    rowStyle: {
      type: [Function, Object],
      default: null
    },
    cellStyle: {
      type: [Function, Object],
      default: null
    }
  },
  data() {
    return {
      multipleSelection: this.defaultMultipleSelection,
      tableVisible: true
    };
  },
  computed: {
    multipleSelectionIds() {
      let list = [];
      this.multipleSelection.forEach((item) => {
        list.push(item.id);
      });
      return list.join(",");
    }
  },
  watch: {
    tableData() {
      this.$refs.tableContent.doLayout();
    },
    columns() {
      this.refreshTable();
    }
  },
  mounted() {
    this.initSelection();
  },
  activated() {
    this.$refs.tableContent.doLayout();
  },
  methods: {
    // 初始化选择项
    initSelection() {
      if (!this.multipleSelection.length) {
        return;
      }
      this.multipleSelection.forEach((item) => {
        this.$refs.multipleTable.toggleRowSelection(item);
      });
    },
    // 勾选
    handleSelectionChange(rows) {
      this.multipleSelection = rows;
      this.$emit("selection-change", rows);
    },
    changePage(data) {
      this.$emit("change-page", data);
      this.$emit("update-data");
    },
    selectable(row) {
      return row.selectable;
    },
    refreshTable() {
      this.tableVisible = false;
      this.$nextTick(() => {
        this.tableVisible = true;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.table-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  background: #fff;
  overflow: hidden;
  &-handle-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    padding: 0 24px;
    ::v-deep .el-button + .el-button {
      margin: 0;
    }
    &-left,
    &-right {
      display: flex;
      gap: 10px;
    }
  }
  &-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 50px;
    padding-right: 60px;
  }
  ::v-deep .el-table {
    min-height: 320px;
  }
  ::v-deep .table-header-cell-style {
    padding: 10px 0;
    background-color: #f5f7fa;
    font-size: 14px;
  }
  ::v-deep .el-table__column-filter-trigger {
    line-height: 1.5;
  }
}
.table-container-shadow {
  box-shadow: rgba(187, 187, 187, 0.3) 0 0 4px 0;
}
</style>
