import { BasicLayout, BlankPage } from "@/components/layouts";
import { ApiUser } from "@/api/user";

const constantRouterComponents = {
  BasicLayout: BasicLayout,
  RouteView: BlankPage,
  404: () => import(/* webpackChunkName: "error" */ "@/views/exception/404"),

  // 你需要动态引入的页面组件
  BuildingList: () => import(/* webpackChunkName: "building" */ "@/views/building/BuildingList"),
  ProjectList: () => import(/* webpackChunkName: "building" */ "@/views/building/ProjectList.vue"),
  UserMan: () => import(/* webpackChunkName: "system" */ "@/views/system/UserMan"),
  RoleMan: () => import(/* webpackChunkName: "system" */ "@/views/system/RoleMan"),
  SpaceList: () => import(/* webpackChunkName: "space" */ "@/views/space/SpaceList"),
  DeviceList: () => import(/* webpackChunkName: "device" */ "@/views/device/DeviceList"),
  MacQuery: () => import(/* webpackChunkName: "macQuery" */ "@/views/macQuery/MacQuery"),
  VersionList: () => import(/* webpackChunkName: "version" */ "@/views/version/VersionList")
};

const keepAliveMenuPagePathList = ["/building", "/building/list", "/building/projectList", "/space/list"];

// 前端未找到页面路由（固定不用改）
const notFoundRouter = {
  path: "*",
  redirect: "/404",
  hidden: true
};

// 根级菜单
const rootRouter = {
  path: "/",
  component: BasicLayout,
  name: "首页",
  meta: '{"title": "首页"}',
  children: []
};

// 动态生成菜单
export const generatorDynamicRouter = () => {
  return new Promise((resolve, reject) => {
    ApiUser.getCurrentUserNav()
      .then((res) => {
        const menuNav = [];
        // 后端数据, 根级树数组,  根级 PID, 找出所有的菜单类
        listToTree(res.menus, menuNav, "");
        let menus = generator(menuNav);
        let pages = generator(res.pages);
        rootRouter.redirect = menus[0] ? menus[0].path : "";
        rootRouter.children = menus.concat(pages);
        let routes = [rootRouter, notFoundRouter];
        resolve(routes);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

/**
 * 格式化树形结构数据 生成 vue-router 层级路由表
 *
 * @param routerMap
 * @param parent
 * @returns {*}
 */
export const generator = (routerMap) => {
  if (!routerMap) {
    return [];
  }
  return routerMap.map((item) => {
    const { hidden, hideChildren, hiddenBreadcrumb, breadcrumb, icon } = (item.meta && JSON.parse(item.meta)) || {};
    const currentRouter = {
      // 如果路由设置了 path，则作为默认 path，否则 路由地址 动态拼接生成如 /dashboard/workplace
      path: item.path,
      // 路由名称，建议唯一
      name: item.permissionCode || "",
      component: constantRouterComponents[item.componentName],

      // meta: 页面标题, 菜单图标, 页面权限(供指令权限用，可去掉)
      meta: {
        title: item.permissionName,
        permission: item.permissionCode,
        hiddenBreadcrumb: hiddenBreadcrumb || false,
        breadcrumb: breadcrumb,
        keepAlive: keepAliveMenuPagePathList.includes(item.path),
        pageLevel: 1
      }
    };
    if (icon) {
      currentRouter.meta.icon = require(`@/assets/sidebar/${icon}.png`);
    }

    if (hidden) {
      currentRouter.hidden = true;
    }

    // 是否设置了隐藏子菜单
    hideChildren && (currentRouter.hideChildren = true);

    // 重定向
    item.redirect && (currentRouter.redirect = item.redirect);

    // 是否有子菜单，并递归处理
    if (item.children && item.children.length > 0) {
      // Recursion
      currentRouter.children = generator(item.children, currentRouter.meta.breadcrumb);
    }
    return currentRouter;
  });
};

/**
 * 数组转树形结构
 * @param list 源数组
 * @param tree 树
 * @param parentId 父ID
 */
const listToTree = (list, tree, parentId) => {
  list = list || [];
  list = list.filter((e) => e);
  list = list.sort((a, b) => a.sort - b.sort);
  list.forEach((item) => {
    // 判断是否为父级菜单
    if (item.pid === parentId) {
      const child = {
        ...item,
        children: []
      };
      // 迭代 list， 找到当前菜单相符合的所有子菜单
      listToTree(list, child.children, item.id);
      // 删掉不存在 children 值的属性
      if (child.children.length <= 0) {
        delete child.children;
      }
      // 加入到树中
      tree.push(child);
    }
  });
};

// 隐藏通用面包屑
export const hiddenBreadcrumb = (pages) => {
  pages.forEach((page) => {
    page.meta = {
      hiddenBreadcrumb: true
    };
    if (page.children && page.children.length) {
      hiddenBreadcrumb(page.children);
    }
  });
};
