/*
 * @Description: 批量导出
 * @Author: hongyan.yang
 * @LastEditors: zhongyuanrui
 * @FilePath: \homeauto-web-v3.0\src\components\index.js
 */
import Vue from "vue";

import TableContainer from "./table/TableContainer.vue";
import TableSearchForm from "./table/TableSearchForm.vue";
import CheckboxGroup from "./checkboxGroup/CheckboxGroup.vue";
import BackButton from "./baseButton/BackButton.vue";
import UploadApk from "./upload/UploadApk.vue";
import MultipleSelect from "./select/MultipleSelect.vue";

Vue.component("back-button", BackButton);
Vue.component("ld-table", TableContainer);
Vue.component("ld-search-bar", TableSearchForm);
Vue.component("ld-checkbox-group", CheckboxGroup);
Vue.component("upload-apk", UploadApk);
Vue.component("multiple-select", MultipleSelect);

export { default as BaseButton } from "./baseButton/BaseButton.vue";
export { default as TableButton } from "./baseButton/TableButton.vue";
export { default as CommonButton } from "./baseButton/CommonButton.vue";
// export { default as CircleButton } from "./baseButton/CircleButton.vue";
export { default as CardTable } from "./cardTable/CardTable.vue";
export { default as BaseHeader } from "./baseHeader/BaseHeader.vue";
export { default as BaseInput } from "./baseInput/BaseInput.vue";
export { default as BasePagination } from "./basePagination/BasePagination.vue";
export { default as BaseTable } from "./baseTable/BaseTable.vue";
export { default as DialogForm } from "./form/DialogForm.vue";
export { default as SearchForm } from "./form/SearchForm.vue";
export { default as Breadcrumb } from "./breadcrumb/Breadcrumb.vue";
export { default as BaseListPage } from "./layouts/BaseListPage.vue";
export { default as BaseCard } from "./baseCard/BaseCard.vue";
export { default as SmallTable } from "./smallTable/SmallTable.vue";
export { default as DialogTransfer } from "./dialogTransfer/DialogTransfer.vue";
export { default as BuildingTree } from "./buildingTree/BuildingTree.vue";
export { default as FormDialog } from "./formDialog/FormDialog.vue";
export { default as AreaCascader } from "./areaCascader/AreaCascader.vue";
export { default as treeSelect } from "./treeSelect/TreeSelect.vue";
export { default as UploadButton } from "./upload/UploadButton.vue";
export { default as UploadImg } from "./upload/UploadImg.vue";
// export { default as ArrowIcon } from "./icon/ArrowIcon.vue";
