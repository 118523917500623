<!--
 * @Description: 
 * @Author: hongyan.yang
 * @LastEditors: hongyan.yang
 * @FilePath: /household/src/components/baseHeader/UserMenu.vue
-->
<template>
  <div class="user-menu">
    <el-dropdown @command="handleCommand">
      <span class="el-dropdown-link"><i class="el-icon-user-solid el-icon--left"></i> {{ user.name }} </span>
      <el-dropdown-menu slot="dropdown">
        <!-- <el-dropdown-item>个人中心</el-dropdown-item>
        <el-dropdown-item>账户设置</el-dropdown-item> -->
        <el-dropdown-item command="logout"><i class="el-icon-user-solid el-icon--left"></i>退出登录</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
import { ApiUser } from "@/api/user";

export default {
  name: "UserMenu",
  computed: {
    user() {
      return this.$store.getters.userInfo.sysUser;
    }
  },
  methods: {
    handleCommand(cmd) {
      if (this[cmd]) this[cmd]();
    },
    logout() {
      ApiUser.logout().then(() => {
        this.$message.success("退出成功");
        this.$ls.clear();
        location.reload();
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.el-dropdown {
  margin: 0 24px 0 10px;
}
</style>
