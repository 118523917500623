import { baseInputProperty } from "../baseInput/baseInputProperty";
import { formProperty } from "./formProperty";

export class dialogFormItemProperty extends baseInputProperty {
  // 上一次的值
  oldValue;

  // show 是否显示该项
  show = true;

  setHidden() {
    this.show = false;
    return this;
  }
}

export class dialogFormProperty extends formProperty {
  title = "标题";

  visible = false;

  // dialog width
  width;

  // form rules
  rules;

  // 重置form值
  resetForm() {
    this.items.forEach(item => {
      item.value = item.oldValue;
    });
  }
}
